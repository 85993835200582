.befirst {
    padding: 80px 0px;
    position: relative;

    .elipse {
        position: absolute;
        top: -368px;
        left: 0px;
        z-index: -1;
    }

    .owner {
        display: flex;
        justify-content: space-between;
    }

    .section_one {
        padding: 60px 0px;
        display: flex;
        justify-content: space-between;

        p {
            color: var(--Accent, #FF0083);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
        }

        h1 {
            color: var(--White, #FFF);
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
        }

        h2 {
            color: var(--White, #FFF);
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
        }

        .whitepara {
            color: var(--White, #FFF);
            max-width: 490px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            padding-top: 18px;
        }

        .whitetext {
            color: var(--Accent, #FF0083);
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
        }

        h3 {
            color: var(--White, #FFF);
            width: 490px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            padding-top: 18px;
        }
    }

    .inner-text {
        h6 {
            color: var(--White, #FFF);
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;

            span {
                color: var(--Accent, #FF0083);
                font-size: 60px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
            }
        }

        p {
            font-style: normal;
            font-size: 26px;
            line-height: 140%;
            color: #FFF;
            font-weight: 400;
        }
    }
}

.main_outer {
    display: flex;
    justify-content: space-between;
    position: relative;

    .right_side {
        width: 759px;

        .flexxdiv {
            display: flex;
            flex-direction: column;
            gap: 78px;
        }

        .innnner_div {
            display: flex;
            justify-content: space-between;

            p {
                color: var(--Accent, #FF0083);
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;
            }

            h3 {
                color: var(--White, #FFF);
                font-size: 20px !important;
                font-style: normal;
                font-weight: 700;
                padding-top: 10px;
                padding-bottom: 10px;
                line-height: 150%;
            }

            .lowerpara {
                color: var(--White, #FFF);
                font-size: 16px !important;
                font-style: normal;
                font-weight: 400;
                width: 250px;
                line-height: 140%;
            }
        }
    }

    .innner_two {
        p {
            color: var(--Accent, #FF0083);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
        }

        h2 {
            color: var(--White, #FFF);
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
        }

        span {
            color: var(--Accent, #FF0083);
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
        }
    }
}

.buydomain {
    padding: 80px 0;

    .elipse2 {
        position: absolute;
    }

    .inner-text {
        h6 {
            font-style: normal;
            font-size: 40px;
            line-height: 120%;
            color: #FFF;
            margin-bottom: 28px;
            font-weight: 700;
        }

        p {
            font-style: normal;
            font-size: 26px;
            line-height: 140%;
            color: #FFF;
            font-weight: 400;
        }
    }
}

.rewardeveryone {
    position: relative;

    .elipse3 {
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        z-index: -1;
        margin-right: auto;
    }
}

.mainsection_three {
    padding: 48px 164px;
    display: flex;
    justify-content: space-between;
    margin-top: 174px;
    position: relative;
    background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);

    border-radius: 30px;

        -webkit-backdrop-filter: blur(35.1px);
        backdrop-filter: blur(35.1px);
    
        &:after {
          content: '';
          position: absolute;
          inset: 0px;
          border-radius: inherit;
          background-color: #ffffff;
          -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
          -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          border: 1.7px solid transparent;
          background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);

          background: linear-gradient(#111111 0 0) padding-box, linear-gradient(181.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
          z-index: -1;
        }

    .left_side {
        p {
            color: var(--White, #FFF);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            width: 300px;
            line-height: 140%;
        }

        h2 {
            color: var(--White, #FFF);
            font-size: 42px !important;
            font-style: normal;
            font-weight: 700;
            padding-bottom: 12px;
            line-height: 120%;

            span {
                color: var(--Accent, #FF0083);
                font-size: 42px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
            }
        }
    }

    .input_type {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    input {
        padding: 10px 12px 10px 24px;
        border-radius: 39px;
        padding-left: 50px;
        border: 1px solid var(--Accent, #FF0083);
        width: 390px;
        color: #FFF;
        background-color: unset;
    }

    button {
        color: var(--White, #FFF);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        text-transform: capitalize;
        display: flex;
        padding: 10px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex: 1 0 0;
        border-radius: 100px;
        border: 1px solid var(--Accent, #FF0083);
        background: var(--Accent, #FF0083);
    }

    .typpppe {
        position: relative;
    }

    .poxxx {
        position: absolute;
        left: 20px;
        top: 9px;
    }


}

.rewardeveryone {
    padding: 80px 0;

    .inner-text {
        h6 {
            font-style: normal;
            font-weight: 700;
            font-size: 40px;
            line-height: 120%;
            color: #FFF;
            margin-bottom: 28px;
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 26px;
            line-height: 140%;
            color: #FFF;
        }
    }
}

.mainlast {
    .innnnnner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 36px;
        align-items: center;
    }
}

.refferprogram {
    object-fit: cover;
    width: 100%;
    overflow: hidden;
    padding-top: 140px;
    position: relative;
    padding-bottom: 140px;

    .myyyelipse {
        position: absolute;
        left: -494px;
        top: -363px;
    }

    .myyyelipse2 {
        position: absolute;
        right: -574px;
        top: -363px;

    }

    h2 {
        color: var(--White, #FFF);
        text-align: center;
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        width: 842px;
        line-height: 100%;

        span {
            color: var(--Accent, #FF0083);
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
        }
    }

    p {
        color: var(--White, #FFF);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        width: 490px;
    }
}

@media (max-width:600px) {
    .xyxssxssyfyf {
        display: none;
    }

    .befirst .inner-text h6 {
        font-size: 28px;
    }

    .befirst .inner-text p {
        font-size: 16px;
    }

    .rewardeveryone .inner-text h6 {
        font-size: 28px;
    }

    .rewardeveryone .inner-text p {
        font-size: 16px;
    }

    .buydomain .inner-text h6 {
        font-size: 28px;
    }

    .buydomain .inner-text p {
        font-size: 16px;
    }

    .refferprogram .inner-text h6 {
        font-size: 28px;
    }

    .refferprogram .inner-text p {
        font-size: 16px;
    }

    .befirst .inner-text {
        padding-top: 50px;
        max-width: 390px;
        width: 100%;
    }

    .rewardeveryone .inner-text {
        padding-top: 50px;
        max-width: 390px;
        width: 100%;
    }

    .buydomain .inner-text {
        padding-top: 50px;
        max-width: 390px;
        width: 100%;
    }

    .refferprogram .inner-text {
        padding-top: 50px;
        max-width: 390px;
        width: 100%;
    }
    .befirst .section_one h2{
        font-size: 40px;
        span{
            font-size: 40px;
        }
    }
    .befirst .section_one .whitetext{
        font-size: 40px;
    }
    .refferprogram h2{
        font-size: 40px;
        width: 100%;
        span{
            font-size: 40px;
        }
    }
}

@media (max-width:1200px) {
    .mainsection_three .left_side p{
        text-align: center;
        width: 100%;
    }
    .befirst .section_one {
        flex-direction: column-reverse;
        justify-content: center;
        gap: 50px;
        text-align: center;
        align-items: center;
        padding-bottom: 0px;
    }

    .befirst .section_one h3 {
        width: 100%;
    }

    .mainsection_three {
        flex-direction: column;
        padding: 48px 10px;
        gap: 60px;
        margin-top: 0px;
    }


    .left_side {
        text-align: center !important;
    }

    .mainsection_three input {
        width: 100%;
    }

    .refferprogram {
        margin-top: 0px;
    }

    .refferprogram h6 {
        width: 100%;
        font-size: 30px !important;

        span {
            font-size: 30px !important;
        }
    }

    .refferprogram p {
        width: 100%;
    }
}

@media (max-width:992px) {
    .main_outer{
        flex-direction: column;
    }
    .main_outer .innner_two h2{
        font-size: 40px;
    }
    .main_outer .innner_two h2{
        font-size: 40px;
    }
    .main_outer .right_side .innnner_div{
        flex-direction: column;
        gap: 50px;
    }
    .main_outer .right_side .flexxdiv{
        gap: 50px;
    }
    .main_outer .right_side{
        width: 100%;
    }
    .buydomain{
        overflow: hidden;
    }
    .buydomain .elipse2{
        width: 100%;
    }
    .main_outer .right_side .innnner_div .lowerpara{
        width: 100%;
    }
    .main_outer .right_side .flexxdiv{
        margin-top: 50px;
    }
}