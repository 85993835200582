.main-signup {
    padding: 80px 0px;
    .signupbox {
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        padding: 20px;

        position: relative;
        $border: 1.5px;
        color: #FFF;
        background: #2b2326;
        background-clip: padding-box;
        border: solid $border transparent;
        border-radius: 30px;

    
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            /* !importanté */
            border-radius: inherit;
            /* !importanté */
            background: var(--gradient, linear-gradient(180deg, #FF0083 0%, #171717 103.07%));
        }
    }

    .main-head {
        h3 {
            
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 120%;
            text-transform: uppercase;
            color: #fff;
            text-align: center;
            margin-bottom: 28px;
        }
    }

    .buttonss {
        display: flex;
        flex-direction: column;

        .btn-pink {
            background:  #FF0083;
            border: 1px solid #D9D9D9;
            box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            padding: 12px 0px;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            color: #FFFFFF;
            // margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .btn-white{
            background:unset;
            border: 1px solid  #FF0083;
            box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            padding: 12px 0px;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            color: #FFFFFF !important;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover{
                color:  #ffff !important;
                background-color: #FF0083;
            }
        }
    }
}


//......signup1 scss....//
.signup1 {
    padding: 80px 0px;
    .signupbox {
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        padding: 20px;


        position: relative;
        $border: 1.5px;
        color: #FFF;
        background: #2b2326;
        background-clip: padding-box;
        border: solid $border transparent;
        border-radius: 30px;

    
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            /* !importanté */
            border-radius: inherit;
            /* !importanté */
            background: var(--gradient, linear-gradient(180deg, #FF0083 0%, #171717 103.07%));
        }
    }

    .main-head {
        h3 {
            
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 120%;
            text-transform: uppercase;
            color: #ffff;
            text-align: center;
            margin-bottom: 28px;
        }
    }

    .emailinput {
        p {
            font-style: normal;
           font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #fff;
        }

        input {
            background: unset;
                border: 1px solid #FF0083;
            box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
            border-radius: 25px;
            width: 100%;
            padding: 20px;
            margin-top: 20px;
            margin-bottom: 20px;
            color: #FFFFFF;


            &::placeholder {
                font-style: normal;
               font-weight: 500;
                font-size: 16px;
                line-height: 120%;
                color: #B1B1B1;
            }
        }

        h6 {
            font-style: normal;
           font-weight: 500;
            font-size: 14px;
            line-height: 120%;
            color: #FF0083;
        }
    }

    .signupbutton {
        button {

            background: #FF0083;
            border-radius: 5px;
            padding: 15px 0px;
            width: 100%;
            align-items: center;
            font-style: normal;
          font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #FFFFFF;
            border: none;
            margin-top: 15px;

        }
    }
    .cancle{
        text-align: center;
        margin-top: 30px;
        margin-bottom: 42px;
        a{
            font-style: normal;
        font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #FF0083;
        
        }
    }
    .brdr{
        border: 1px solid #DCDCDC;
        width: 100%;
        height: 0px;
    }
    .account{
        text-align: center;
        margin-top: 13px;
        h4{
            font-style: normal;
        font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #9B9B9B;
        }
    }
    .login-btn{
        margin-top: 27px;
        margin-bottom: 20px;
        button {
    
            background: unset;
            border-radius: 5px;
            padding: 15px 0px;
            width: 100%;
            align-items: center;
            font-style: normal;
          font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #FF0083;
            border: none;
            margin-top: 15px;
            border: 1px solid #FF0083;
    }
    }
}


///resetpassword scss......///
.resetpassword{
padding: 80px 0px;
.signupbox {
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    padding: 20px;
    position: relative;
        $border: 1.5px;
        color: #FFF;
        background: #2b2326;
        background-clip: padding-box;
        border: solid $border transparent;
        border-radius: 30px;

    
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            /* !importanté */
            border-radius: inherit;
            /* !importanté */
            background: var(--gradient, linear-gradient(180deg, #FF0083 0%, #171717 103.07%));
        }
}
.main-head {
    h3 {
        
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 120%;
        text-transform: uppercase;
        color: #fff;
        text-align: center;
        margin-bottom: 28px;
    }
}
.emailinput {
    p {
        font-style: normal;
       font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: #fff;
    }

    input {
        background: unset;
        border: 1px solid #FF0083;
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 25px;
        width: 100%;
        padding: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
        color: #FFFFFF;

        &::placeholder {
            font-style: normal;
           font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #B1B1B1;
        }
    }
}
.passwordreset {
    button {
    
            background: unset;
            border-radius: 5px;
            padding: 15px 0px;
            width: 100%;
            align-items: center;
            font-style: normal;
          font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #FF0083;
            border: none;
            margin-top: 15px;
            border: 1px solid #FF0083;
       
    
        

    }
    .send{
        background-color: #FF0083 !important;
        color:#FFFFFF !important;
    }
}
}


///.... success scss////
.success{
    padding: 80px 0px;
    .signupbox {
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        padding: 20px;
        position: relative;
        $border: 1.5px;
        color: #FFF;
        background: #2b2326;
        background-clip: padding-box;
        border: solid $border transparent;
        border-radius: 30px;

    
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            /* !importanté */
            border-radius: inherit;
            /* !importanté */
            background: var(--gradient, linear-gradient(180deg, #FF0083 0%, #171717 103.07%));
        }
    }
    .main-head {
        h3 {
            
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 120%;
            text-transform: uppercase;
            color: #fff;
            text-align: center;
            margin-bottom: 28px;
        }
    }
    .main-para{
        .para{
            font-style: normal;
           font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #fff;
            margin-top: 20px;
            margin-bottom: 20px;
        }
        span{
            font-style: normal;
           font-weight: 500;
             font-size: 16px;
             line-height: 120%;
             color: #FF0083;
             margin-top: 10px;
        }
    }
    .passwordreset {
        margin-top: 27px;
        margin-bottom: 20px;
        button {
    
            background: unset;
            border-radius: 5px;
            padding: 15px 0px;
            width: 100%;
            align-items: center;
            font-style: normal;
          font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #FF0083;
            border: none;
            margin-top: 15px;
            border: 1px solid #FF0083;
            &:hover{
                background-color: #FF0083;
                color:#FFFFFF;
            }
    
        }
    }
}


///...... signup2.....///
.main-signup2{
    padding: 80px 0px;
    .signupbox {
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        padding: 20px;
        position: relative;
        $border: 1.5px;
        color: #FFF;
        background: #2b2326;
        background-clip: padding-box;
        border: solid $border transparent;
        border-radius: 30px;

    
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            /* !importanté */
            border-radius: inherit;
            /* !importanté */
            background: var(--gradient, linear-gradient(180deg, #FF0083 0%, #171717 103.07%));
        }
    }

    .main-head {
        h3 {
            
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 120%;
            text-transform: uppercase;
            color: #fff;
            text-align: center;
            margin-bottom: 28px;
        }
    }

    .buttonss {
        display: flex;
        flex-direction: column;

        .btn-pink {
            background-color: #FF0083;
            border: 1px solid #D9D9D9;
            box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            padding: 12px 0px;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            color: #FFFFFF;
            // margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
           
        }
        
        .btn-white {
            background-color: unset;;
            border: 1px solid #FF0083;
            box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            padding: 12px 0px;
            width: 100%;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            color: #fff !important;
            // margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover{
                color:  #ffff !important;
                background-color: #FF0083;
            }
        }
    }
}


//......login scss.....//


.login{
    padding: 80px 0px;
        .signupbox {
            box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            padding: 20px;


            position: relative;
        $border: 1.5px;
        color: #FFF;
        background: #2b2326;
        background-clip: padding-box;
        border: solid $border transparent;
        border-radius: 30px;

    
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            /* !importanté */
            border-radius: inherit;
            /* !importanté */
            background: var(--gradient, linear-gradient(180deg, #FF0083 0%, #171717 103.07%));
        }
        }
        .logo-head{
            text-align: center;
            margin-bottom: 32px;
        }
        .loginbox{
            background: unset;
           color: #fff;
            border-radius: 5px;
            padding: 13px 18px;
            p{
                font-style: normal;
               font-weight: 500;
                font-size: 14px;
                line-height: 140%;
                color: #fff;
            }
        }
        .logohead{
            text-align: center;
            margin-top: 20px;
            margin-bottom: 20px;
            h2{
                
                font-style: normal;
                font-weight: 700;
                font-size: 26px !important;
                line-height: 120%;
                text-transform: uppercase;
                color: #fff;
            }
            span{
                
                font-style: normal;
                font-size: 26px;
                line-height: 120%;
                text-transform: uppercase;
                color: #FF0083;
            }
            input {
                background: unset;
                border: 1px solid #FF0083;
                box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
                border-radius: 25px;
                width: 100%;
                padding: 20px;
                margin-top: 20px;
                margin-bottom: 10px;
                color: #FFFFFF;

    
                &::placeholder {
                    font-style: normal;
                   font-weight: 500;
                    font-size: 16px;
                    line-height: 120%;
                    color: #B1B1B1;
                }
            }
        }
        .terms{
            p{
                font-style: normal;
               font-weight: 500;
                font-size: 14px;
                line-height: 130%;
                color: #A1A1A1;
            }
            span{
                font-style: normal;
               font-weight: 500;
                font-size: 14px;
                line-height: 130%;
                color: #FF0083;
            }
        }
        .continue{
            background: #FF0083;
            border-radius: 5px;
            padding: 15px;
            font-style: normal;
           font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            color: #FFFFFF !important;
            width: 100%;
            display: block;
            text-align: center;
            margin-top: 25px;
        }
    }    
