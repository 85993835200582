@import url("https://fonts.googleapis.com/css2?family=Mada:wght@200..900&display=swap");

@font-face {
  font-family: Gregular; //fontweight : 400
  src: url(../src/font/Gellix-Regular.woff);
}

@font-face {
  font-family: Gmedium; //fontweight : 500
  src: url(../src/font/Gellix-Medium.woff);
}

@font-face {
  font-family: Gsemibold; //fontweight : 600
  src: url(../src/font/Gellix-SemiBold.woff);
}

@font-face {
  font-family: Gbold; //fontweight : 700
  src: url(../src/font/Gellix-Bold.woff);
}

@font-face {
  font-family: Gextrabold; //fontweight : 800
  src: url(../src/font/Gellix-ExtraBold.woff);
}

html {
  scroll-behavior: smooth;
}
body {
  font-family: "Mada", sans-serif;
  background: var(--bg-100, #111);
  height: 100vh;
  --wcm-z-index: 9999 !important;
}

.row {
  margin: 0;
  padding: 0;
}

.p-0 {
  padding: 0px;
}

.p0 {
  padding: 0px;
}

// All transitions
div,
ul,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button {
  -webkit-transition: background-color 0.7s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

textarea {
  resize: none;
  height: 60px;
  width: 100%;
}

a {
  text-decoration: none !important;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

div,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0;
}

h1 {
  font-size: 55px !important;
  font-style: normal !important;
  font-weight: bold !important;
  // text-transform: uppercase !important;
}

.grey {
  color: #888 !important;
}

.grey2 {
  color: #475569;
}

.ptb20 {
  padding: 20px 0;
}

.btn-common {
  background: #ff0083;
  border-radius: 8px;
  margin: 10px 0;
  padding: 10px 15px;
  color: #fff;
  text-decoration: none;
  border: none;
  font-size: 13px;
  text-transform: uppercase;

  &:hover {
    color: #ff0083;
    box-shadow: 0px 4px 4px #00000040;
    background-color: #fff;
  }
  &:active {
    color: #ff0083;
    box-shadow: 0px 4px 4px #00000040;
    background-color: #fff;
  }
  &:focus {
    outline: none;
  }
}

.ptb {
  padding: 60px 0;
}

.btn-common2 {
  background: transparent;
  border-radius: 8px;
  margin: 10px 0;
  padding: 8px 10px;

  color: #ff0083;
  border: 1px solid #ff0083;
  text-decoration: none;
  font-size: 13px;
  text-transform: uppercase;
  &:hover {
    color: #ff0083 !important;
    box-shadow: 0px 4px 4px #00000040;
    background-color: #fff;
  }
  &:active {
    color: #ff0083 !important;
    box-shadow: 0px 4px 4px #00000040;
    background-color: #fff;
  }
  &:focus {
    outline: none;
  }
}

.btn-red {
  background: #f41e5e;
  box-shadow: 0px 4px 4px #00000040;
  border-radius: 10px;
  box-sizing: border-box;
  color: #fff !important;
  padding: 8px 15px;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #f41e5e !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #fff;
  }
}
.btn-white {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  color: #aa3935 !important;
  padding: 8px 15px;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    color: #fff !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: #aa3935;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.purple {
  color: #6c01c3;
}
.yellow {
  color: #ffc13d;
}
.border-img {
  border-radius: 10px;
}

.common {
  color: #ff0083;
}

.green {
  color: #6dff39;
}

.red {
  color: #f41e5e;
}
.white {
  color: #fff !important;
}

.blue {
  color: #133572;
}

.navbar-toggle {
  background-color: #333;

  .icon-bar {
    background-color: #eee;
  }
}

.card-style {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;

  .btn-common {
    width: 100%;
  }

  .inner-user {
    margin: 10px 0px;
  }
  .time {
    background: #fafafa;
    border: 1px solid #f1f1f1;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px 0;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 10px 0px;
  }

  h5 {
    margin: 5px 0px 5px;
  }

  h6 {
    font-size: 13px;
  }
}
.brdr-all {
  width: 100%;
  background-color: #e2e8f0;
  margin: 30px 0;
  height: 1px;
  border-radius: 20px;
}

@media only screen and (max-width: 600px) {
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .main-footer {
    text-align: center;

    .text-right {
      text-align: center !important;
    }
  }

  h1 {
    font-size: 2.5rem !important;
  }

  .ptb {
    padding: 30px 0;
  }

  .xs-0 {
    padding: 0px;
  }

  .main-mint,
  .main-banner {
    .inner-content {
      .modal-content {
        .for-padding {
          padding: 0 0px !important;
        }
      }
    }
  }

  .main-give {
    .right-side {
      margin-top: 30px !important;
      h5 {
        font-weight: 500;
      }
      li {
        margin-right: 2px !important;
      }
    }
  }
}
// 12 pro
@media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
  .nav-brand {
    margin-left: 15px;
  }
  .right-side {
    margin-top: 30px;
    h5 {
      font-weight: 500;
    }
    li {
      margin-right: 8px !important;
    }
  }

  h1 {
    font-size: 3rem !important;
  }

  .ptb {
    padding: 20px 0;
  }
}
// 12 pro max
@media only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) {
  .nav-brand {
    margin-left: 15px;
  }

  .right-side {
    margin-top: 30px;
    h5 {
      font-weight: 500;
    }
    li {
      margin-right: 8px !important;
    }
  }

  .ptb {
    padding: 20px 0;
  }
}

.dnnf {
  text-align: end !important;
}
iframe {
  position: unset !important;
  top: 0px !important;
  left: 0px !important;
  width: 0px !important;
  height: 0px !important;
}

button {
  cursor: pointer;
}

.table th {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 100%;
  color: #fff !important;
}

.table td span {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 130%;
  color: #fff !important;
}

.btn-loadmore button {
  transition: 0.7s;
  &:hover {
    background: #f41e5e !important;
    color: #fff !important;
  }
}

.table .btn-blue1 {
  transition: 0.7s;
  &:hover {
    background: #f41e5e !important;
    color: #fff !important;
  }
}

.btn-searchbar {
  border: none !important;
  transition: 0.7s;
  border: 2px solid transparent !important;
  &:hover {
    background: #f41e5e;
    // border: 2px solid #ffffff !important;
  }
}

.btn-pink {
  border: 2px solid transparent !important;
  &:hover {
    background: transparent !important;
    color: #ff0083 !important;
    border: 2px solid #ff0083 !important;
  }
}

@media (max-width: 600px) {
  h2 {
    // font-size: 30px !important;
  }
  .col-md-6 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .col-md-8 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .col-md-4 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
button:focus {
  outline: none !important;
}
input:focus {
  outline: none !important;
}

.form-control:focus {
  box-shadow: unset;
}

@media (max-width: 600px) {
  .modal.show .modal-dialog {
    max-width: 100% !important;
  }
}

button {
  cursor: pointer;
}
a {
  cursor: pointer;
}

button,
input,
optgroup,
select,
textarea:focus-visible {
  outline: none !important;
}
button,
input,
optgroup,
select,
textarea:focus {
  outline: none !important;
}
button.close {
  opacity: 1;
}

.active-next i {
  color: #ff0083;
}

.toast-success {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  .fade.show {
    border-radius: 9px;
    border: none;
  }
  border-radius: 9px;
  border: none;
  .toast-header {
    background: #6ac259;
    border-radius: 9px;
    justify-content: space-between;
    padding: 15px;
    position: relative;
    .btn-close {
      background: url("./Assests/toast-close.svg") !important;
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      border: none;
      padding: 10px;
      position: absolute;
      top: 10px;
      right: 15px;
      z-index: 999;
      opacity: 1;
    }
    .parent {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .left {
        margin-right: 10px;
      }
      .right {
        h6 {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 100%;
          color: #ffffff;
          margin-bottom: 6px;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          color: #ffffff;
        }
      }
    }
  }
}
.toast-error {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  .fade.show {
    border-radius: 9px;
    border: none;
  }
  border-radius: 9px;
  border: none;
  .toast-header {
    background: #f05228;
    border-radius: 9px;
    justify-content: space-between;
    padding: 15px;
    position: relative;
    .btn-close {
      background: url("./Assests/toast-close.svg") !important;
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      border: none;
      padding: 10px;
      position: absolute;
      top: 10px;
      right: 15px;
      z-index: 999;
      opacity: 1;
    }
    .parent {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .left {
        margin-right: 10px;
      }
      .right {
        h6 {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 100%;
          color: #ffffff;
          margin-bottom: 6px;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          color: #ffffff;
        }
      }
    }
  }
}
.toast-info {
  position: fixed !important;
  top: 70px !important;
  right: 0 !important;
  .fade.show {
    border-radius: 9px;
    border: none;
  }
  border-radius: 9px;
  border: none;
  .toast-header {
    background: rgb(57, 206, 255);
    border-radius: 9px;
    justify-content: space-between;
    padding: 15px;
    position: relative;
    .btn-close {
      background: url("./Assests/toast-close.svg") !important;
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      border: none;
      padding: 10px;
      position: absolute;
      top: 10px;
      right: 15px;
      z-index: 999;
      opacity: 1;
    }
    .parent {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .left {
        margin-right: 10px;
      }
      .right {
        h6 {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 100%;
          color: #ffffff;
          margin-bottom: 6px;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          color: #ffffff;
        }
      }
    }
  }
}
.toast-warning {
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  .fade.show {
    border-radius: 9px;
    border: none;
  }
  .toast-header {
    background: #ffcc4d;
    border-radius: 9px;
    justify-content: space-between;
    padding: 15px;
    position: relative;
    border: none;
    .btn-close {
      background: url("./Assests/toast-close.svg") !important;
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      border: none;
      padding: 10px;
      position: absolute;
      top: 10px;
      right: 15px;
      z-index: 999;
      opacity: 1;
    }
    .parent {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .left {
        margin-right: 10px;
      }
      .right {
        h6 {
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 100%;
          color: #ffffff;
          margin-bottom: 6px;
        }
        p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          color: #ffffff;
        }
      }
    }
  }
}
.Toastify__toast {
  border-radius: 9px !important;
}
.Toastify__toast-body > div:last-child {
  flex: 1 1;

  font-weight: 700;
  font-size: 18px;
  line-height: 100%;
}
.toast-info {
  position: fixed !important;
  top: 92px !important;
  right: 0 !important;
  border-radius: 9px;
  border: none;
}
::-moz-selection {
  /* Code for Firefox */
  color: white !important;
  background: #ff0083;
}

::selection {
  color: white !important;
  background: #ff0083;
}

.bg-secondary {
  background-color: #282828 !important;
}

.custom-container {
  max-width: 1240px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 2000px) {
  .custom-container {
    max-width: 1800px;
    width: 100%;
    margin: 0 auto;
    padding: 0 100px;
  }
}

.custom-containerl {
  max-width: 1350px;
  width: 100%;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 2000px) {
  .custom-containerl {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
  }
}

.liveauction .custom-container {
  max-width: 1240px !important;
  width: 100% !important;
  margin: 0 auto !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.liveauction .market .cardsss {
  grid-template-columns: repeat(4, 1fr) !important;
}

@media (max-width: 1199px) {
  .liveauction .market .cardsss {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

@media (max-width: 991px) {
  .liveauction .market .cardsss {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .liveauction .card-img-top-div {
    height: px !important;
  }
  .liveauction .custom-container {
    padding: 0 !important;
  }
}

@media (max-width: 430px) {
  .liveauction .card-img-top-div {
    height: px !important;
  }
}

@media (max-width: 390px) {
  // .liveauction .card-img-top-div{
  //   height: 322px !important;
  // }
}

@media (max-width: 600px) {
  .liveauction .market .cardsss {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

.centershowing-text {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  margin-top: 55px !important;
}

a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: #ff0083;
}

.claimdomainpara {
  font-weight: 700;
  font-size: 20px;
  line-height: normal;
  font-style: normal;
  color: #fff;
}

.auctionendtext {
  font-weight: 700;
  font-size: 22px;
  line-height: normal;
  font-style: normal;
  color: #ff0083;
  .whitetext {
    color: #fff;
  }
}

.myheight {
  // height: 544px ;
  // overflow-y: auto;
}

.btn-docs {
  background: transparent;
  border: 1px solid #ff0083;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  outline: none;
  border-radius: 100px;
  color: #ff0083;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  max-width: fit-content;
  margin-top: 20px;
  &:hover {
    background: #ff0083;
    color: #fff;
    transition: 0.5s;
    svg path {
      fill: #ff0083;
      stroke: #fff;
      transition: 0.5s;
    }
  }
}

.copiedparatext {
  position: absolute;
  right: -60px;
  top: 0px;
}

.copiedparatextimg {
  position: absolute;
  right: -30px;
  top: -4px;
}

.newmyheight {
  height: 267px !important;
}
.categorymodal {
  .modal-dialog {
    .modal-content {
      border-radius: 15px;
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.03) 0%,
        rgba(120, 120, 120, 0.03) 100%
      );
      border: 1px solid #ffffff1a;
      backdrop-filter: blur(160px);
      .modal-header {
        border: unset;
        // cursor: pointer;
      }
      .modal-body {
        padding: 0px;
        .modal__body {
          display: flex;
          flex-direction: column;
          align-items: center;
          h5 {
            color: #fff;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            /* 24px */
            padding-top: 30px;
          }
          .circleiiner {
            width: 156px;
            height: 156px;
          }
          p {
            color: #bcbcbc;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            /* 22.4px */
          }
          h2 {
            color: #ff0083;
            text-align: center;
            padding-bottom: 30px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          button {
            color: #fff;
            border: unset;
            font-size: 16px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            width: 218px;
            padding: 15px 0px;
            border-radius: 120px;
            background: #ff0083;
            margin-bottom: 54px;
          }
          // button:hover{
          //   color: #FFFFFF;
          //   background-color: var(--lightPrimary);
          //   border-color: var(--lightPrimary);
          // }
        }
      }
    }
  }
}
.modal-header .btn-close {
  background: url(../src/Assests/cross.svg);
  background-repeat: no-repeat;
  border: unset;
  box-shadow: unset;
  opacity: unset;
  height: 30px;
  width: 27px;
  cursor: pointer;
  margin-top: 2px;
}
.modal-backdrop.fade {
  opacity: 0 !important;
  background: rgb(0 0 0 / 50%) !important;
  // z-index: 9999999 !important;
}
.modal-open .modal {
  opacity: unset !important;
  background: rgb(0 0 0 / 50%) !important;
  backdrop-filter: unset !important;
}

.reed {
  color: #fdc8c8 !important;
}
