.order-complete {
    margin-top: 50px;
    .thankyou-div {
        background: #ffffff;
        border: 1px solid #efefef;
        /* Save */

        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 5px 5px 0px 0px;
        text-align: center;
        padding: 0px 80px 50px 80px;
        background: #ffffff;
        border: 1px solid #efefef;


        position: relative;
        $border: 1.5px;
        color: #FFF;
        background: #2b2326;
        background-clip: padding-box;
        border: solid $border transparent;
        border-radius: 30px;

    
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            /* !importanté */
            border-radius: inherit;
            /* !importanté */
            background: var(--gradient, linear-gradient(180deg, #FF0083 0%, #171717 103.07%));
        }
        h1 {
            font-weight: 600;
            font-style: normal;
            font-size: 38px !important;
            color: #ff0083;
        }
        .pHH {
            font-weight: 600;
            font-style: normal;
            font-size: 18px !important;
            color: #fff;
            padding: 15px 0px 34px 0px;
            span {
                font-size: 23px !important;
                color: #000000;
            }
        }
        .totalDiv {
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: 2px solid #EFEFEF;
            border-bottom: 2px solid #EFEFEF;
            padding: 20px 0px;
            p {
                font-weight: 500;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                color: #fff;
            }
            h6{
                font-weight: 600;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                color: #070707;
                span{
                    font-weight: 500;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    color: #ff0083;
                }
            }
        }
    }
    .mintNow{
        background: #061643;
        padding: 13px 20px;
        border-radius: 0px 0px 5px 5px;
h6{
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    color: #FFFFFF;
    
}
p{
    font-weight: 500;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #D6D5D5;
}
    }
}
@media (max-width:601px){
    .order-complete .thankyou-div {
        padding: 0px 13px 44px 13px;
    }
}