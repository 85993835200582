.market {
  min-height: 100vh;
  .card {
    position: relative;
    /* other styling for your card */
  }
  
  .buttton_mains {
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Smooth transition for the button */
  }
  
  .card:hover .buttton_mains {
    opacity: 1; /* Button becomes visible on hover */
  }
  .buttton_mains{
    position: absolute;
    bottom: 20px;
    right: 20px;
    left: 20px;
    opacity: 0;
  }
  .buttton_mains:hover{
    opacity: 1;
    transition: 0.3s;
  }
  .view_detaill{
    color: var(--White, #FFF);
text-align: center;
width: 100%;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
text-transform: capitalize;
padding: 10px 16px;
border-radius: 100px;
border: 1px solid var(--Accent, #FF0083);
background: var(--Accent, #FF0083);
  }
  .bidteext{
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
  }
  .botttoms_cards {
    display: flex;
    padding: 10px 15px;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.05);

    p {
        color: #9D9D9D;

        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 12px */
    }

    h1 {
        color: #FFF !important;
        text-align: right !important;

        font-size: 12px !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: 120%;
        /* 14.4px */
        padding-top: 10px;
    }
}
  .btn.focus,
  .btn:focus {
    box-shadow: none;
  }

  .drop1 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .btn-1 {
    padding: 20px;
    background: #ffffff;
    border: 1px solid #ffdbed;
    border-radius: 10px;
    box-sizing: border-box;
    margin-left: 10px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .sortcolr {
    color: #ff0083 !important;
    font-size: 16.818px !important;
    padding: 15px 30px;
    white-space: nowrap;
  }

  #dropdownMenuButton {
    cursor: pointer;
  }

  .btnin {
    border: 1px solid #ffdbed;
    border-right: none;
    background: #ffffff;
    border-radius: 10px;
  }

  .icon-search {
    margin-left: 15px;
  }

  button {
    padding: 0;
  }

  .btn-2 {
    padding: 17px 22px;
    background: #ffffff;
    border: 1px solid #fbeaf3;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #ff0083;

    &:hover {
      background: #ff0083;
      color: #fff;
    }
  }

  .dropdown-toggle::after {
    color: #ff0083;
  }

  .btn-div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card-body-1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 99%;
    margin: 0 auto;
  }

  .card-body-2 {
    background: #fafafa;
    border: 1px solid #ffdbed;
    border-radius: 10px;
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    padding: 14px;
    align-items: baseline;
  }

  .cardsss {
    display: grid;
    // grid-template-columns: repeat(auto-fill, 23%);
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    grid-gap: 20px;

  }

  .img-1 {
    position: relative;
    left: -15px;
    width: 35px;
    height: 35.7px;
    object-fit: cover;
    border-radius: 50%;
  }

  .card {
    background-image: url(../../Assests/domaincard.svg) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    /* Ensures the image covers the entire container */
    background-position: center !important;
    padding: 22px;
    border-radius: 10px;
    border: 1.5px solid #282828;
    // background: #111;
    
    box-shadow: unset !important;
    position: relative;
    min-height: 274px;
    position: relative;
    background: linear-gradient(103deg, rgba(255, 160, 198, 0.15) 0%, rgba(126, 126, 126, 0.01) 110.97%), rgba(29, 29, 29, 0.20);


    border-radius: 20.333px;


        -webkit-backdrop-filter: blur(35.1px);
        backdrop-filter: blur(35.1px);
    
        &:after {
          content: '';
          position: absolute;
          inset: 0px;
          border-radius: inherit;
          background-color: #ffffff;
          -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
          -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          border: 1.7px solid transparent;
          background: linear-gradient(103deg, rgba(255, 160, 198, 0.15) 0%, rgba(126, 126, 126, 0.01) 110.97%), rgba(29, 29, 29, 0.20);


          background: linear-gradient(#111111 0 0) padding-box, linear-gradient(181.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
          z-index: -1;
        }
    .cardimg {
      position: absolute;
      bottom: 0px;
      right: 0px;
    }

    // margin-bottom: 25px;

    .card-img-top {
      margin-bottom: 23px;
    }
  }

  .card-heading-1 {
    display: flex;
    padding: 5px 10px;
    align-items: center;
    gap: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: #FFF;
    border-radius: 30px;
    background: #FF0083;
    max-width: fit-content;
  }

  .card-heading-4 {
    color: #FFF;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-transform: capitalize;
    margin-bottom: 12px;
  }

  .card-style-2 {
    width: 100%;

    .endedspan {
      border-radius: 6px;
      background: #FF0083;
      display: flex;
      height: 33px;
      padding: 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      color: #FFF;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      max-width: fit-content;
      position: absolute;
      bottom: 33px;
      left: 30px;
    }
  }

  .card-heading-6 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .dots {
      width: 100%;
      display: flex;
      justify-content: center;
      color: #FFF;
      align-items: center;
    }

    .timermain {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      .timerpara {
        color: #FFF;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
      }

      .timerinner {
        display: flex;
        width: 38px;
        height: 33px;
        padding: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 6px;
        border-radius: 5.545px;
        background: rgba(255, 255, 255, 0.10);
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
      }
    }
  }

  .card-heading-2 {
    color: #EAEAEA;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    text-transform: uppercase;
    gap: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 22px;
    display: flex;

    align-items: center;

    .mytomiicon {
      width: 25px;
      height: 25px;
    }
  }

  .card-heading-3 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #FFF;
  }

  .card-heading-4 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    color: #FFF;
  }

  .input-field {
    position: relative;

    .search-img {
      position: absolute;
      top: 21px;
      left: 11px;
    }

    input {
      background: #ffffff;
      border: 1px solid #ffdbed;
      border-radius: 10px;
      width: 100%;
      padding: 21px 50px;
    }
  }
.butttttonn{
  color: #FFF;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 100%; /* 16px */
height: 51px;
padding: 17px 39px;
border-radius: 60px;
position: absolute;
border: unset;
right: 5px;
top: 4px;
background: #FF0083;
}
  .domainSearh {
    border-radius: 10px;
    margin-top: 60px;
    background: #1F1F1F;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 18px;
    display: flex;
    justify-content: space-between;
    position: relative;
    background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);

    border-radius: 12px;
        -webkit-backdrop-filter: blur(35.1px);
        backdrop-filter: blur(35.1px);
    
        &:after {
          content: '';
          position: absolute;
          inset: 0px;
          border-radius: inherit;
          background-color: #ffffff;
          -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
          -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          border: 1.7px solid transparent;
          background: linear-gradient(103deg, rgba(255, 160, 198, 0.15) 0%, rgba(126, 126, 126, 0.01) 110.97%), rgba(29, 29, 29, 0.20);


          background: linear-gradient(#111111 0 0) padding-box, linear-gradient(181.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
          z-index: -1;
        }
    // margin-left: 15px;
    // margin-right: 15px;
    .pspans {
      display: flex;
      align-items: center;

      span {
        margin-right: 39px;
        display: flex;
        align-items: center;

        p {
          font-weight: 500;
          font-style: normal;
          font-size: 18px;
          color: #FFF;
          margin-right: 13px;

          &:hover{
            color: #FF0083;
          }
        }
      }
    }
  }

  .searchfeild {
    input {
      background: #ffffff;
      border: 1px solid #efefef;
      border-radius: 10px;
      padding: 18px;
      position: absolute;
      top: 0px;
      left: -1px;
      width: 100%;
      color: #000000;

      &::placeholder {
        font-style: normal;
        font-weight: 500;
        font-size: 18px !important;
        line-height: 100%;
      }
    }

    span {
      position: absolute;
      top: 5px;
      right: 14px;
      color: deeppink;
      font-size: 30px;
      font-weight: 700;
    }
  }
.newwwww_bar{
  width: 100%;
}
  .search-bar {
    background: #333;
    border-radius: 5px;
    padding: 18px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #FFF;
    border: none;
    width: 100%;
    padding-left: 50px;
    border-radius: 8000px;
border: 1px solid rgba(255, 255, 255, 0.03);
background: rgba(255, 255, 255, 0.03);
box-shadow: 0px 10.511px 52.555px 0px rgba(0, 0, 0, 0.05);

    &::placeholder {
      color: #B1B1B1;
    
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 18px */
    }
  }

  .searc-icon {
    position: absolute;
    top: 17px;
    left: 15px;
  }
}
.pspans{
  display: flex;
  align-items: center;
  // width:600px;
  span p{
    padding: 15px 30px;
  }
}
::-webkit-scrollbar {
  display: none;
}
.upper_domainnss{
  padding-top: 150px;
 .btttttt{
    color: #FF0083;
text-align: center;
text-transform: none;
font-size: 70px !important;
font-style: normal;
font-weight: 900 !important;
line-height: 110%; /* 77px */


  }
  .btttttty{
    color: var(--White, #FFF);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
  }
  .mydpayyy{
    color: var(--White, #FFF);
    text-transform: none !important;
  font-size: 70px;
  font-style: normal;
  font-weight: 900 !important;
  line-height: 110%;
  }
}
.banner-bg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 783px;
  object-fit: cover;
}
.card-img-top-div {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  // font-family: "Gellix";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  text-align: center;
  color: #fff;
}

@media (max-width: 1199px) {
  .market .input-field {
    margin-bottom: 25px;
  }

  .market .search-bar {
    width: 210px;
  }

  .market .domainSearh .pspans span {
    margin-right: 16px;
  }
}

@media (max-width: 991px) {
  .market .main-heading h1 {
    font-size: 34px !important;
  }

  .card-img-top-div {
    height: 283px;
  }
}

@media (max-width: 600px) {
  .market .padd {
    padding: 0;
  }
  .butttttonn {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    height: 36px !important;
    padding: 8px 20px !important;
    border-radius: 60px;
    position: absolute;
    border: unset;
    right: 12px !important;
    top: 9px !important;
    background: #FF0083;
}
  .market .btn-2 {
    margin-bottom: 100px;
  }

  .market .searchfeild input {
    top: 30px;
  }
  .market .card {
    width: 100%;
  }
  .market .searchfeild span {
    top: 36px;
  }
  .upper_domainnss .mydpayyy {
    font-size: 40px;
  }
  .market .searchfeild input {
    top: 21px;
    left: -1px;
    width: 100%;
    padding: 25px 18px;
  }

  .market .searchfeild span {
    top: 38px;
  }

  .market .availableDomain .availtick {
    padding-left: 0 !important;
  }

  .market .domainSearh {
    background: transparent;
    border: none;
    box-shadow: none;
    flex-direction: column;
    padding: 0;
  }

  .market .search-bar {
    width: 100% !important;
    border-radius: 5px;
    border-radius: 10px;
    position: relative;
    background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);

    border-radius: 12px;
        -webkit-backdrop-filter: blur(35.1px);
        backdrop-filter: blur(35.1px);
    
        &:after {
          content: '';
          position: absolute;
          inset: 0px;
          border-radius: inherit;
          background-color: #ffffff;
          -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
          -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          border: 1.7px solid transparent;
          background: linear-gradient(103deg, rgba(255, 160, 198, 0.15) 0%, rgba(126, 126, 126, 0.01) 110.97%), rgba(29, 29, 29, 0.20);


          background: linear-gradient(#111111 0 0) padding-box, linear-gradient(181.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
          z-index: -1;
        }
  }

  .market .domainSearh .sImg {
    position: absolute;
    top: 18px;
    right: 20px;
    width: 22px;
    height: 22px;
  }

  .market .domainSearh .pspans {
    margin-bottom: 10px;
    justify-content: space-between;
  }

  .market .domainSearh .pspans span {
    width: 48%;
    margin: 0 auto;
    justify-content: center;
    padding: 16px;
    margin-bottom: 10px;
  }

  .market .domainSearh .pspans span p {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: #FFF;
  }

  .market .domainSearh {
    margin-top: 20px;
  }

  .card-img-top-div {
    height: 360px;
  }
}

@media (max-width: 430px) {
  .market .main-heading h1 {
    font-size: 20px !important;
  }
}

@media (max-width:390px) {
  .card-img-top-div {
    height: 300px;
  }
}

@media (max-width: 380px) {
  .market .main-heading h1 {
    font-size: 16px !important;
  }
}

.imgfigure {
  width: 35.7px;
  height: 32.9px !important;
  object-fit: cover;
  border-radius: 50%;
}

@media (max-width: 600px) {
  .market .domainSearh .pspans {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: visible;
  }

  .market .domainSearh .pspans span p {
    white-space: nowrap;
  }

  .market .domainSearh .pspans span :nth-child(2) {
    margin-right: 39px;
    display: flex;
    align-items: center;
    margin: 15px 0px;
  }

  .market .domainSearh {
    margin-left: 0;
    margin-right: 0;
  }
}

.information .bid-card {
  height: 200px;
}

.imgDiv {
  position: absolute;
  top: 32%;
  left: 24%;
  background: white;
  width: 160px;
  text-align: center;
  height: 40px;
}

@media (max-width: 768px) {
  .imgDiv {
    position: absolute;
    top: 32%;
    left: 24%;
    background: white;
    width: 200px;
    text-align: center;
    height: 65px;
  }
}

// #scrollableDiv{
//   overflow: auto;
//   height: 50vh;
// }

@media (max-width:1199px) {
  .market .cardsss {
    // grid-template-columns: repeat(auto-fill, 28%);
    grid-template-columns: repeat(3, 1fr);
  }

  .card-img-top-div {
    height: 260px;
  }
}


@media (max-width:911px) {
  .market .cardsss {
    // grid-template-columns: repeat(auto-fill, 48%);
    grid-template-columns: repeat(2, 1fr);
  }

  .card-img-top-div {
    height: 360px;
  }
}

@media (max-width:600px) {
  .market .cardsss {
    grid-template-columns: repeat(1, 1fr);
  }
  .pspans span p{
    padding: 15px;
    font-size: 14px;
  }
  .market .sortcolr{
    padding: 15px;
    font-size: 14px;
  }
}

@media (max-width:390px) {
  .card-img-top-div {
    height: 300px;
  }
}


@media(min-width:2000px) {
  .market .cardsss {
    grid-template-columns: repeat(auto-fill, 18.7%);
  }
}

// @media(min-width:2300px) {
//   .market .cardsss {
//     grid-template-columns: repeat(auto-fill, 11.5%);
//   }
// }

.cNAllowed {
  cursor: not-allowed !important;
  color: lightgrey !important;
}