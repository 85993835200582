.liveauction {
    padding: 60px;
    padding-bottom: 80px;
    margin-top: 100px;
    // background: #151515;

    .top-heading {
        padding-bottom: 50px;

        h2 {
            color: var(--White, #FFF);
            text-align: center;
            font-size: 60px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;

            /* 60px */
            span {
                color: var(--Accent, #FF0083);

                font-size: 60px;
                font-style: normal;
                font-weight: 700;
                line-height: 100%;
            }

        }

        p {
            color: var(--Accent, #FF0083);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            /* 20.8px */
        }

        .lowertext {
            color: var(--White, #FFF);
            text-align: center;
            max-width: 839px;
            font-size: 16px;
            margin: 18px auto 0;
            font-style: normal;
            font-weight: 900;
            line-height: 140%;
            
        }
    }

    .see-more {
        background: #FF0083;
        box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
        border-radius: 5px;
        padding: 16px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #FFFFFF;
        display: block;
        border: none;
        margin: 0 auto;
        margin-bottom: 42px;
        width: 184px;
    }
}


.set-text-font {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    // line-height: 100%;
    color: #FFFFFF;
display: flex;
align-items: center;
justify-content: center;
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.myydivnew{
    position: relative;
    .eliiiip{
        position: absolute;
        right: -17px;
        top: -284px;
    }
}

.liveAuc {
    .market {
        min-height: auto !important;
    }
    .cardnnn{
        display: flex !important;
        justify-content: center !important;
        gap: 20px;
        // flex-wrap: wrap;
    }
    .cardn {
   
        padding: 24px;
        position: relative;
        $border: 1.5px;
        color: #FFF;
        width: 315px;
        background: #2b2326;
        background-clip: padding-box;
        border: solid $border transparent;
        border-radius: 10px;
    
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            /* !importanté */
            border-radius: inherit;
            /* !importanté */
            background: var(--gradient, linear-gradient(180deg, #FF0083 0%, #171717 103.07%));
        }
    
        .cardimg {
          position: absolute;
          bottom: 0px;
          right: 0px;
        }
    
        // margin-bottom: 25px;
        // &:hover {
        //   border-radius: 10px;
        //   border: 1.5px solid #FF0083;
        //   background: #111;
        //   box-shadow: 0px 0px 17px 0px #FF0083, 0px 7px 12px 0px rgba(0, 0, 0, 0.10);
        // }
    
        .card-img-top {
          margin-bottom: 23px;
        }
      }
      .viewbutton{
        color: var(--White, #FFF);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%; /* 24px */
      text-transform: capitalize;
      margin: 0 auto;
      margin-top: 40px;
      display: flex;
      padding: 10px 9px 10px 18px;
cursor: pointer !important;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 100px;
      border: 1px solid var(--Accent, #FF0083) !important;
      background: unset;
      }
}

@media (min-width: 2300px) {
    .liveAuc .market .cardsss {
        grid-template-columns: repeat(auto-fill, 23.5%) !important;
    }
}

@media (min-width: 2000px) {
    .liveAuc .market .cardsss {
        grid-template-columns: repeat(auto-fill, 23.5%);
    }
  
}
@media (max-width: 1600px) {
    .liveAuc .cardnnn {
        flex-wrap: wrap !important;
    }
}
@media (min-width: 600px) {
    .liveauction .top-heading p {
        width: 100% !important;
    }

    .liveauction {
        padding: 20px !important;
    }
}
@media(max-width:660px){
    .liveauction .top-heading h2{
        font-size: 40px;
        span{
            font-size: 40px;
        }
    }
    .liveauction .top-heading{
        width: 100%;
    }
}