.banner-new {
    position: relative;
    z-index: 50;
    padding-top: 158px;
    // background: #151515;

    .banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 783px;
        object-fit: cover;
    }

    .inner-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 132px;

        .pinkbtn {
            display: flex;
            padding: 10px 16px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 100px;
            border: 1px solid #FF0083;
            color: var(--White, #FFF);
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            text-transform: capitalize;
            max-width: fit-content;
            margin: 0 auto 0;

            &:hover {
                background-color: #FF0083;
            }
        }

        .inner-text {

            h6 {

                color: var(--White, #FFF);
                text-align: center;

                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;

                /* 22.4px */
                span {
                    color: var(--Accent, #FF0083);
                    text-align: center;
                    padding-bottom: 8px;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    /* 22.4px */
                }
            }

            h1 {
                color: var(--White, var(--White, #FFF));
                text-align: center;
                margin-bottom: 8px;
                font-size: 70px;
                font-style: normal;
                font-weight: 900;
                line-height: 110%; 
                span {
                    color: var(--Accent, #FF0083);
                }
            }

            p {
                color: var(--White, #FFF);
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }

            .greyishpara {
                color: var(--White, var(--White, #FFF));
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                margin-bottom: 8px;

                .domainsspan {
                    color: var(--Accent, #FF0083) !important;
                }
            }
        }

        .option-field {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 13px;
            margin-bottom: 32px;
            margin-top: 30px;

            .search-icon {
                position: absolute;
                top: 20px;
                left: 20px;
            }

            input {
                width: 527px;
                padding: 12px 122px 12px 24px;
                border-radius: 39px;
                border: 1px solid var(--Accent, #FF0083);
                color: var(--White, #FFF);
                background-color: unset;
                height: 68px;
                padding-left: 60px;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                /* 22.4px */

                &::placeholder {
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 100%;
                }
            }

            .btn-search {
                color: var(--White, #FFF);
                text-align: center;

                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%;
                /* 24px */
                text-transform: capitalize;
                padding: 10px 16px;
                border-radius: 100px;
                border: 1px solid var(--Accent, #FF0083);
                background: var(--Accent, #FF0083);
                width: 100%;
                height: 65px;
                position: absolute;
                right: 26px;
                top: 12px;
                max-width: 87px;
                width: 100%;
                height: 44px;

            }
        }

        .timer {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;

            .timer-text {
                font-style: normal;
                font-weight: 700;
                font-size: 17px;
                line-height: 100%;
                text-transform: capitalize;
                color: #FFFFFF;
            }

            .timer-div {
                display: flex;
                align-items: center;
                gap: 17px;

                .inner-timer {
                    max-width: 50px;
                    width: 100%;

                    h6 {
                        border-radius: 6px;
                        background: #333;
                        padding: 10px;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 110%;
                        color: #FFF;
                        text-align: center;
                        width: 50px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 110%;
                        text-align: center;
                        color: #FFFFFF;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

.floating-btn-telegram {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 999;

    button {
        border: none;
        background-color: transparent;
    }
}

.bottom-contenttt {
    position: relative;
    z-index: 50;
    border-radius: 10px;
    background: #1F1F1F;
    box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
    padding: 60px 98.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 50px;

    .inner-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex: 0 0 170px;

        h6 {
            font-style: normal;
            font-weight: 600;
            font-size: 22px;
            line-height: 120%;
            text-align: center;
            text-transform: uppercase;
            color: #FFF;
            margin-top: 20px;
        }
    }
}

@media (max-width:1199px) {
    .banner-new .banner-bg {
        height: 100%;
        padding-bottom: 80px;
    }

    .banner-new .bottom-contenttt {
        padding: 30px;
        align-items: flex-start;
    }

    .banner-new .bottom-contenttt .inner-content h6 {
        font-size: 18px;
    }

    .banner-new .inner-content .option-field input {
        width: 100%;
        font-size: 20px;
    }

    .banner-new .inner-content {
        margin-top: 20px;
        padding-top: 20px;
    }

    .banner-new .inner-content .option-field {
        width: 100%;
    }

    .text-centerr {
        text-align: center !important;
    }

    .ordersm-2 {
        order: 2;
    }

    .banner-new .inner-content .inner-text h1 {
        font-size: 40px !important;
    }

    .banner-new .inner-content .inner-text h1 span {
        font-size: 40px;
    }
}

@media (max-width:600px) {
    .banner-new .banner-bg {
        // display: none !important;
    }

    .banner-new {
        padding-top: 100px;
    }

    .banner-new .bottom-contenttt {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .banner-new .inner-content .timer {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }

    .banner-new .inner-text {
        text-align: center;
    }

    .banner-new .inner-content .option-field .btn-search {
        // position: static;
    }

    .banner-new .inner-content .option-field {
        flex-direction: column;
    }

    .banner-new .inner-content .option-field .btn-search {
        // max-width: 100%;
        // width: 100%;
        // border-radius: 10px;
    }

    .banner-new .banner-bg {
        height: 1092px;
    }

    .banner-new .bottom-contenttt {
        padding-top: 0;
    }

    .banner-new .bottom-contenttt .inner-content h6 {
        max-width: 142px;
        width: 100%;
    }

    .liveauction {
        padding: 20px;
    }

    .liveauction .top-heading {
        margin-left: 0 !important;
    }

    .banner-new-img img {
        max-width: 300px;
        width: 100%;
        display: block;
        margin: 0 auto;
    }

    .banner-new .inner-content .inner-text h6 {
        font-size: 15px;
    }

    .banner-new .inner-content .inner-text h2 {}

    .banner-new .inner-content .option-field input {
        height: 65px;
    }

    .banner-new .inner-content .option-field .search-icon {
        top: 18px !important;
    }

    .banner-new .inner-content .option-field .btn-search {
        // height: 65px !important;
    }

    .dgcvdvtdctcdvtvd {
        width: 100%;
    }

    .banner-new .inner-content .option-field input {
        padding-right: 124px !important;
    }
}


// .banner-new-img{
//     position: relative;
//     z-index: 10;
//     .banner-img-shadow{
//         position: absolute;
//         bottom: -100px;
//         left: 20px;
//         z-index: 0;
//     }
// }

.button-hedaerrrrr {
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    text-transform: capitalize;
    color: #FF0083;
}



// @media (min-width: 2000px){
//     .banner-new .inner-content .option-field{
//         width: 70%;
//     }
//     .banner-new .banner-bg{
//         height: 1050px;
//     }
// }

.tooltip-inner {
    background: #FFFFFF;
    border-radius: 6px;
    padding: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #616161;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.23));
    margin-right: 20px;

    &::after {
        content: ' ';
        height: 0;
        position: absolute;
        width: 0;
        top: 16px;
        right: -29px;
        border: 15px solid transparent;
        border-left-color: #fff;

    }
}

@media (max-width:600px) {
    .floating-btn-telegram {
        display: none !important;
    }

    .floating-btn-telegrammobile {
        display: block !important;
        position: relative;

        .main-set {
            position: fixed;
            bottom: 20px;
            right: 20px;
            z-index: 999999999;
            width: 100%;
            text-align: right;
        }

        .tele-icon {
            width: 60px;
            height: 60px;

            &:hover {
                +.inner-hover {
                    display: block !important;
                    position: absolute;
                    top: 0;
                    right: 80px;
                    background: #FFFFFF;
                    border-radius: 6px;
                    padding: 10px;
                    max-width: 190px;

                    p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 120%;
                        color: #616161;
                    }
                }
            }
        }
    }
}