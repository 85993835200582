.watchlist {
    margin-top: 100px;
    .watchlist-h {
        margin-top: 80px;
        text-align: center;
        h1 {
            font-weight: 600;
            font-style: normal;
            font-size: 45px;
            color: #ff0083;
        }
        p {
            font-weight: 600;
            font-style: normal;
            font-size: 20px;
            /* identical to box height, or 20px */

            color: #fff;
        }
    }
    .wat-h {
        font-weight: 600;
        font-style: normal;
        font-size: 45px;
        color: #ff0083;
    }
    .input-field{
        position: relative;
        .inpppput{
            border-radius: 39px;
            border: 1px solid var(--Accent, #FF0083);
            padding: 12px 12px 12px 44px;
            background-color: unset;
            width: 400px;
        }
        .input-icon{
            position: absolute;
            left: 14px;
            top: 12px;
        }
        .crosImg{
            position: absolute;
            left: 356px;
            top: 12px;
        
        }
    }
}
.banner-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 783px;
    object-fit: cover;
}