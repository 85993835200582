.main-navbarnew {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 26px 0;
    z-index: 9999;

    .navbar-brand {
        display: inline-block;
        font-size: 1.25rem;
        white-space: nowrap;
    }

    .common {
        color: #ff0083 !important;
    }

    .navbar-nav {
        margin-left: 79px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        margin-left: 0;
        margin-top: 8px;
        

        &:hover {
            color: #ff0083 !important;
        }
    }

    .bg-color {
        background-color: #ff0083 !important;
    }

    .style-bar {
        background-color: #ff0083;
        border-radius: 4px;
    }

    .form-inline {
        a {
            color: black;
            padding: 0px 15px;
            display: inline-block;
        }

        .btn-common-nav {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            color: #ffffff;
            padding: 16px 40px;
            background: #ff0083;
            justify-content: center;
            align-items: center;
            border: 1px solid #ff0083;

            &:hover {
                background-color: #ffffff;
                color: #000000;
                border: 1px solid #ff0083;
            }
        }
    }

    .img200 {
        width: 34px;
        height: 34px;
        object-fit: cover;
        border-radius: 50%;
    }

    .wallet-modal {
        .modal-dialog {
            // margin: 16.75rem auto;
        }

        .modal.show .modal-dialog {
            max-width: 746px !important;
        }

        .modal-content {
            height: 343px;
            background: #ffffff;
            border: 1px solid #f1f1f1;
            box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
        }

        .modal-body {
            position: relative;

            .cross-img1 {
                position: absolute;
                top: 21px;
                right: 21px;
            }

            h3 {
                font-style: normal;
                font-weight: 600;
                font-size: 22px;
                margin-bottom: 29px;
                margin-top: 29px;
                text-align: center;
                color: #121212;
            }
        }

        .button-modal1 {
            .modal-button {
                width: 373px;
                height: 182px;
                background: #ffffff;
                border-width: 1px 1px 1px 0px;
                border: 1px solid #e7e7e7;

                &:hover {
                    background-color: #fff8fb;
                }

                h3 {
                    font-weight: 600;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;

                    margin: 0px;
                    text-align: center;
                    margin-top: 10px;
                    margin-bottom: 8px;

                    color: #000000;
                }

                p {
                    font-weight: 500;
                    font-size: 14px;
                    margin-bottom: 32px;
                    font-weight: 400;
                    text-align: center;

                    color: #acacac;
                }
            }
        }

        .text-center {
            font-weight: 500;
            font-size: 14px;
            font-weight: 600;
            text-align: center;

            color: #acacac;

            span {
                font-weight: 500;
                font-size: 14px;
                text-align: center;
                color: #ff0083;
                font-weight: 600;
            }
        }
    }

    .login-btn {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        color: #ff0083 !important;
        border-radius: 5px;
        transition: 0.7s;
        cursor: pointer;
        // &:hover{
        //   background: #FF0083;
        //   box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
        //   border-radius: 5px;
        //   padding: 16px 35px;
        //   font-style: normal;
        //font-weight: 600;
        //   font-size: 16px;
        //   line-height: 100%;
        //   color: #FFFFFF !important;
        // }
    }

    .signup-btn {
        background: #ff0083;
        box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
        border-radius: 5px;
        padding: 16px 35px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #ffffff !important;
        margin-left: 25px;
        cursor: pointer;
    }
}
.myyyyyyyy{

    padding: 30px;

    width: 337px;
    position: relative;
    background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);


    border-radius: 20px;
background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);
backdrop-filter: blur(38.48500061035156px);
    
        &:after {
          content: '';
          position: absolute;
          inset: 0px;
          border-radius: inherit;
          background-color: #ffffff;
          -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
          -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          border: 1.7px solid transparent;
          background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);

          backdrop-filter: blur(38.48500061035156px);
          background: linear-gradient(#111111 0 0) padding-box, linear-gradient(181.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
          z-index: -1;
        }
}
@media (max-width: 430px) {
    .button-modal1 {
        flex-direction: column !important;
        display: flex !important;
    }

    .main-navbarnew .wallet-modal .modal-content {
        width: 377px !important;
        height: 501px !important;
        background: #ffffff !important;
    }
}

@media (max-width: 360px) {
    .navbar-brand {
        width: 67%;
    }

    .main-navbarnew .style-bar {
        width: 28px;
    }
}

.navbar {
    position: relative;
    padding: 21px 60px;
    border-radius: 120px;
background: linear-gradient(90deg, rgba(246, 132, 191, 0.10) 0%, rgba(132, 27, 81, 0.10) 100%);
backdrop-filter: blur(10px);
height: 70px;

    .ahsbahsba {
        position: absolute;
        height: 100%;
        left: 50%;
        top: 0px;
        transform: translateX(-50%);

        img {
            transition: transform 0.6s ease 0s;
            width: 146px;
            transform: scale(1) translateY(92px);
        }
    }
}

.main-navbarnew .navbar-nav .dropdown .dropdown-menu li a:hover {
    color: #000;
    border-radius: 8px;
}

.ashvshsv {
    text-decoration: none !important;
    border: none !important;
}

.signxs {
    font-style: normal;
    font-size: 16px;
    line-height: 100%;
    color: #ff0083;
    background-color: transparent;
    outline: none !important;
    box-shadow: none !important;
    cursor: pointer !important;
    border: none !important;
}

.main-navbarnew .navbar-nav .dropdown .dropdown-menu li a {
    border-radius: 8px;
}

.button-hedaer {
    color: var(--White, #FFF);
    text-align: center;
   
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    text-transform: capitalize;
    display: flex;
padding: 10px 16px;
justify-content: center;
align-items: center;
gap: 4px;
border-radius: 100px;
border: 1px solid var(--Accent, #FF0083);
background: var(--Accent, #FF0083);
    cursor: pointer;
    margin-left: 0 !important;

    &:hover {
        background-color: transparent;
        border: 1px solid var(--Accent, #FF0083);
        color: #FF0083;
    }
}

.main-navbarnew {
    border-bottom: 1px solid transparent;
    z-index: 155;
    padding-left: 0px;
    transition: height 0.2s ease 0s;
    background: #fafafa;
    width: 100%;

    .navbar-nav {
        .nav-link {
            transition: 0.4s ease-in-out;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 110%;
            padding: 0.5rem 0px;
            color: #9d9d9d;
            margin-right: 40px;
            border: 2px solid transparent;

            &:active {
                color: #000;
            }

            &:hover {
                font-style: normal;
                color: #000000;
                font-size: 16px;
                line-height: 110%;
                border-bottom: 2px solid #ff0083;
            }
        }

        .nav-links {
            display: block;
            padding: 0.5rem 0px;
            font-style: normal;
            text-decoration: none !important;
            color: #000000;
            font-size: 16px;
            line-height: 110%;
            margin-right: 40px;
            border-bottom: 2px solid #ff0083;
        }
    }

    .btn-1 {
        margin-right: 25px;
        background: transparent !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #ff0083;
        cursor: pointer;
    }

    .btn-2 {
        cursor: pointer;
    }

    .hbhcdbh {
        padding-left: 11px;
    }
}

.togg i {
    color: #000000 !important;
}

.main-navbarnew .nav-item {
    margin-bottom: 8px !important;

    a {
        text-decoration: none;
        transition: 0.7s;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: #FFFFFF;

        &:hover {
            color: #ff0083;
        }
    }
}

.main-navbar-mobilenew {
    transition: 0.4s ease-in-out;
    width: 100%;
    // position: fixed;
    transition: height 0.2s ease 0s;
    border: none;

    .navbar-nav .dropdown .dropdown-menu li a {
        border-radius: 8px;
    }

    .ahsbahsba {
        img {
            transform: scale(0.8) translateY(45px);
        }
    }

    background: transparent;

    .nav-item {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 100%;
        margin-right: 35px;
        align-items: center;
        justify-content: center;
        align-content: center;
        color: gray;
    }

    .navbar-nav {
        .nav-link {
            transition: 0.4s ease-in-out;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 110%;
            padding: 0.5rem 0px;
            color: #9d9d9d;
            margin-right: 40px;
            border: 2px solid transparent;

            &:active {
                color: #000;
            }

            &:hover {
                font-style: normal;
                color: #000000;
                font-size: 16px;
                line-height: 110%;
                border-bottom: 2px solid #ff0083;
            }
        }

        .nav-links {
            transition: 0.4s ease-in-out;
            display: block;
            padding: 0.5rem 0px;
            font-style: normal;
            text-decoration: none !important;
            color: #000000;
            font-size: 16px;
            line-height: 110%;
            margin-right: 40px;
            border-bottom: 2px solid #ff0083;
        }
    }

    .btn-1 {
        margin-right: 25px;
        background: transparent !important;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        color: #ff0083;
        cursor: pointer;
    }

    .btn-2 {
        cursor: pointer;
    }

    .hbhcdbh {
        padding-left: 11px;
    }
}

.wrapper-color-cahnge .navbar-toggler {
    outline: none !important;

    .togg {
        i {
            color: #fff !important;
        }
    }
}

@media (max-width: 2560px) {
    .sdvgdvsdhvd {
        display: none;
    }
}

@media (max-width: 1199px) {
    .main-navbarnew {
        padding-bottom: 40px;
    }

    .main-navbar-mobilenew .navbar-nav .nav-link:hover {
        border: none !important;
    }

    .navbar-collapse {
        background-color: #141414;
        border-radius: 18px;
        padding-bottom: 30px;
    }

    .main-navbarnew {
        height: unset;
        padding-top: 0px;
    }

    .main-navbarnew .navbar-nav .nav-links {
        border-bottom: none;
        text-align: center;
        margin-right: 0px;
        color: #FFF !important;
    }

    .navbar-nav {
        margin-top: 30px;
    }

    .main-navbar-mobilenew .navbar-nav .nav-links {
        border-bottom: none;
        text-align: center;
        margin-right: 0px;
    }

    .main-navbar-mobilenew .navbar-nav .nav-link {
        text-align: center;
        margin-right: 0px;
        padding-bottom: 15px !important;
    }

    .navbar .ahsbahsba {
        display: none;
    }

    .button-hedaer {
        margin-left: 0px;
        margin-top: 20px;
    }

    .shdvs {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .main-navbarnew .sbvsx {
        display: flex;
        margin: 0 auto;
    }

    .main-navbarnew .navbar-nav .nav-link {
        text-align: center;
        margin-right: 0px;
    }

    .main-navbarnew .navbar-nav .nav-item.active .nav-link::before {
        content: "";
        bottom: 0px;
        right: 0;
        left: 0;
        position: absolute !important;
        width: 14% !important;
        margin: 0 auto;
        height: 3px !important;
    }

    .main-navbarnew {
        .form-inline {
            justify-content: center;
        }
    }

    .main-navbarnew .navbar-nav {
        align-items: center;
        margin: 0 auto;
        margin-top: 20px;
    }

    .main-navbarnew .button-hedaer {
        margin: 0 auto;
        margin-bottom: 15px;
    }

    .main-navbar-mobilenew .nav-item {
        margin: 0 !important;
        margin-bottom: 15px !important;
    }

    .nav-drop .dropdown-menu.show {
        left: unset !important;
        right: 340px !important;
    }
}

@media (max-width: 1199px) {
    .main-navbarnew .login-btn {
        display: block;
        margin: 0 auto;
        text-align: center;
    }

    .main-navbarnew .signup-btn {
        display: block;
        margin: 0 auto;
        margin-top: 12px;
        text-align: center;
    }
}

@media (max-width: 991px) {
    .navbar-nav {
        margin-top: 30px;
    }

    .main-navbar-mobilenew .navbar-nav .nav-links {
        border-bottom: none;
        text-align: center;
        margin-right: 0px;
    }

    .main-navbar-mobilenew .navbar-nav .nav-link {
        text-align: center;
        margin-right: 0px;
    }

    .main-navbarnew .navbar-nav .nav-links {
        border-bottom: none;
        text-align: center;
        margin-right: 0px;
    }

    .main-navbarnew {
        .formm-mm {
            .input-bbb {
                margin-left: 0px;
            }
        }

        .form-inline {
            justify-content: center;
        }
    }

    .main-navbarnew .navbar-nav .nav-link {
        text-align: center;
        margin-right: 0px;
        margin: 0 !important;
        padding-bottom: 15px !important;
    }

    .main-navbarnew .navbar-nav .nav-item.active .nav-link::before {
        content: "";
        bottom: 0px;
        right: 0;
        left: 0;
        position: absolute !important;
        width: 14% !important;
        margin: 0 auto;
        height: 3px !important;
    }

    .main-navbarnew .navbar-nav .dropdown .dropdown-menu {
        position: unset;
    }

    .nav-drop .dropdown-menu.show {
        left: unset !important;
        right: 260px !important;
    }
}

@media (max-width: 600px) {
    .hbdsjbd {
        width: 167px;
    }

    .modal-body .main_text .bhvchvh {
        font-size: 43px !important;
    }

    .main-navbarnew .navbar-nav {
        align-items: center;
        margin: 0 auto;
        margin-top: 20px;
    }

    .main-navbar-mobilenew .nav-item {
        margin: 0;
        padding: 10px 0;
    }

    .main-navbarnew .button-hedaer {
        margin: 10px auto !important;
    }

    .main-navbarnew .wallet-modal .modal-content {
        // margin: 0 auto;
    }

    .main-navbarnew .wallet-modal .button-modal1 .modal-button {
        width: 100%;
    }

    // .main-navbarnew .wallet-modal .modal-body .cross-img1 {
    //   position: absolute;
    //   top: 15px;
    //   left: 335px;
    // }
    .main-navbarnew .wallet-modal .modal-content {
        width: 100% !important;
    }

    .nav-drop .dropdown-menu.show {
        left: unset !important;
        right: 25px !important;
    }

    .nav-drop .dropdown-menu.show .boxy {
        right: 138px !important;
    }

    .main-navbarnew .wallet-modal .modal-dialog {
        // margin: 11.75rem auto;
    }

    .main-navbar-mobilenew .nav-item {
        margin: 0 !important;
    }
}

.buttonsss {
    background-color: transparent;
    border: none;
}

@media (max-width: 400px) {
    .navbar-brand {
        img {
            width: 167px;
        }
    }
}

@media (max-width: 400px) {
    .modal-body .main_text .bhvchvh {
        font-size: 30px !important;
    }

    // .main-navbarnew .wallet-modal .modal-body .cross-img1 {
    //   position: absolute;
    //   top: 15px;
    //   left: 318px;
    // }
    .nav-drop .dropdown-menu.show {
        left: 30px !important;
        top: 0;
        right: 0px !important;
    }
}

@media (max-width: 360px) {
    .navbar-brand {
        img {
            width: 150px;
        }
    }

    .nav-drop .dropdown-menu.show {
        width: 256px !important;
    }

    .main-navbarnew .wallet-modal .modal-body .cross-img1 {
        left: 287px;
    }
}

.sbjdfbsj {
    display: none;
}

.nav-drop {
    .dropdown-menu.show {
        left: -82px;
        width: 320px;
        border: none;
        box-sizing: border-box;
        border-radius: 10px;
        top: 70px;
        box-shadow: 0px 1px 13px 1px #ccc;
        padding: 18px 10px;

        h3 {
            font-style: normal;
            font-weight: 600;
            font-size: 28px;
            line-height: 18px;
            color: #131524;
            margin-bottom: 15px;
        }

        .boxy {
            width: 20px;
            height: 20px;
            position: absolute;
            background-color: #fff;
            right: 5px;
            top: -25px;
            transform: rotate(44deg);
        }

        .list-inline {
            background: #fbfbfb;
            border: 1px solid #d1d8e8;
            box-sizing: border-box;
            margin: 10px 0;
            padding: 10px;
            border-radius: 10px;

            img {
                position: relative;
                bottom: 10px;
            }
        }
    }

    .text-truncate {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #adb2bd;
        opacity: 0.8;
        margin-right: 10px;
    }

    .common-text {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 100%;
        color: #131524;

        span {
            color: #ff0083;
        }
    }

    .bottom-text {
        img {
            display: inline-block;
            margin-right: 6px;
            margin-bottom: 6px;
        }

        p {
            display: inline-block;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #adb2bd;
            opacity: 0.8;

            &:hover {
                color: #ff0083;
            }
        }
    }
}

@media (max-width: 600px) {
    .nav-drop .dropdown-menu.show {
        left: 30px !important;
        top: 0 !important;
        right: 0px !important;
    }
}

.connectwalletmodal {
    .modal-dialog {
        max-width: 558px;

        .modal-content {
            border-radius: 10px;
    background: #111111;
        }

        .modal-body {
            .main-heading {
                position: relative;
                margin-top: 0px;
                padding: 40px 40;

                h3 {

                    font-style: normal;
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 30px;
                    color: #FFF;
                    text-align: center;
                }

                .crossimg {
                    position: absolute;
                    top: 1px;
                    right: 0px;
                }
            }

            .clear {
                text-align: center;

                button {
                    background: #ffffff;
                    border-radius: 15px;
                    padding-top: 24px;
                    line-height: 100%;
                    text-align: center;
                    border: none;
                    cursor: pointer;
                    // width: 449px;
                    border-top: 1px solid #eaeaea;
                    width: 100%;
                    margin-bottom: 15px;
                    border-radius: 0px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 100%;
                    color: #ff0083;
                }
            }

            .Submitproposal {
                .iconimages {
                    display: flex;
                    margin-top: 50px;
                    margin-bottom: 50px;
                    justify-content: center;
                    align-items: center;

                    .leftimage {
                        background: transparent;
                        border-radius: 15px;
                        //    width: 215px;
                        //    height: 215px;
                        width: 50%;
                        text-align: center;
                        border: 1.5px solid transparent;
                        cursor: pointer;

                        //  padding: 44px 61px;
                        img {
                            margin-top: 30px;
                        }

                        &:hover {
                            border: 1.5px solid #ff0083;
                            border-radius: 5px;
                        }

                        p {

                            font-style: normal;
                            font-weight: 400;
                            font-size: 25px;
                            line-height: 30px;
                            color: #FFF;
                            padding-top: 5px;
                            padding-bottom: 44px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .connectwalletmodal .modal-dialog .modal-body .clear button {
        white-space: nowrap;
        font-size: 14px;
        text-align: center;
    }

    .connectwalletmodal .modal-dialog .modal-body .Submitproposal .iconimages .leftimage p {
        font-size: 16px;
    }

    .connectwalletmodal .modal-dialog .modal-body .main-heading h3 {
        font-size: 22px;
    }
}

.profile-dropdown .dropdown-toggle {
    background: transparent !important;
    border: 1px solid #ff0083;
    border-radius: 5px;
    padding: 12px 15px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #ff0083;
    display: flex;
    align-items: center;

    img {
        margin-right: 10px;
    }

    &::after {
        display: none;
    }

    &:focus {
        box-shadow: none !important;
    }
}

.profile-dropdown {
    .dropdown-menu {
        
       
        left: unset;
        right: 0 !important;
        background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);
        border-radius: 20px;

overflow: hidden;
        border: unset;

        .header {
            padding-bottom: 20px;
            border-bottom: unset;   
            h6 {
                font-style: normal;
                font-weight: 700;
                font-size: 22px;
                line-height: 22px;
                color: #FFF;
            }
        }

        .parent {
            display: flex;
            align-items: center;
            padding: 20px 0;

            .black path {
                fill: #FFF;
            }

            .left {
                width: 44px;
                height: 44px;
                border-radius: 50%;
                border: 1px solid #282828;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 8px;
            }

            .right {
                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    color: #FFF;
                }

                h6 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 22px;
                    color: #FFF;
                }
            }

            &:hover {
                .left {
                    background-color: #ff0083;
                }

                .change-my-color path {
                    fill: #fff;
                }
            }
        }

        .redeem {
            display: flex;
            align-items: center;
            border-top: 1.5px solid #282828;
            border-bottom: 1.5px solid #282828;
            padding: 20px 0;

            h6 {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                color: #FFF;
            }
        }

        .bottom-content {
            .multi {
                display: flex;
                align-items: center;
                padding-bottom: 20px;

                &:last-child {
                    padding-bottom: 0;
                }

                h6 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 26px;
                    color: #FFF;
                }

                .change-my-color path {
                    fill: #fff;
                }

                a {
                    &:hover {
                        h6 {
                            color: #ff0083;
                        }

                        .change-my-color path {
                            fill: #ff0083;
                        }
                    }
                }
            }
        }
    }
}

.mLogWid {
    max-width: 150px;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    background-image: transparent !important;
    border: 1px solid #ff0083 !important;
    border-radius: 5px;
    color: #ff0083 !important;
}

@media (max-width: 1199px) {
    .profile-dropdown .dropdown-toggle {
        width: 100%;
        margin: 20px 0;
        justify-content: center;
    }
    .sycvcvsvscyvscvsv {
        flex-direction: column;
        justify-content: center !important;
        align-items: center !important;
        margin: 0 auto;
    }

    .profile-dropdown .dropdown-menu {
        width: 100%;
    }
}

.disconnect-icon path {
    fill: #404040;
}

@media (max-width: 600px) {
    .mLogWid {
        max-width: 100px;
    }
}

.modal-Nav {
    z-index: 99909 !important;
}

.dyvyvcvydvdyvcdvy {
    justify-content: space-between;
}

.sycvcvsvscyvscvsv {
    display: flex;
    align-items: center;
    gap: 15px;
}

@media (max-width:600px) {
    .sycvcvsvscyvscvsv {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .main-navbarnew .navbar-nav {
        margin: 0 auto !important;
        margin-top: 30px !important;
    }

    .profile-dropdown .dropdown-toggle {
        margin: 0 auto !important;
    }

    .profile-dropdown {
        width: 100%;
    }

    .profile-dropdown .dropdown-toggle {
        width: 80%;
    }
}