.creditCard-main {
    margin-top: 50px;
    input {
        padding: 14px 22px;
        font-weight: 500;
        font-style: normal;
        font-size: 16px;
        color: #b1b1b1;
        background:unset;
        border: 1px solid var(--Accent, #FF0083);
        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 25px;
        width: 100%;
        &::placeholder{
            color: #b1b1b1;
        }
    }
    .label {
        font-weight: 500;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #fff;
        margin-bottom: 5px;
        margin-top: 25px;
    }
    .orderSummary {
        padding: 20px;
        background: #ffffff;
        border: 1px solid #efefef;
        /* Save */

        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 10px;


        position: relative;
        $border: 1.5px;
        color: #FFF;
        background: #2b2326;
        background-clip: padding-box;
        border: solid $border transparent;
        border-radius: 30px;

    
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            /* !importanté */
            border-radius: inherit;
            /* !importanté */
            background: var(--gradient, linear-gradient(180deg, #FF0083 0%, #171717 103.07%));
        }
        .heading {
            text-align: center;
            border-bottom: 1px solid #efefef;
            h3 {
                font-weight: 700;
                font-style: normal;
                font-size: 24px;
                color: #fff;
                margin-bottom: 15px;
            }
            p {
                font-weight: 500;
                font-style: normal;
                font-size: 16px;
                color: #ff0083;
                margin-bottom: 20px;
            }
        }
        .domain-price {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin: 20px 0px;
            span {
                h6 {
                    font-weight: 600;
                    font-style: normal;
                    font-size: 18px;
                    color: #fff;
                }
                p {
                    font-weight: 500;
                    font-style: normal;
                    font-size: 16px;
                    color: #7c7c7c;
                }
            }
            h4 {
                font-weight: 600;
                font-style: normal;
                font-size: 18px;
                color: #fff;
            }
            div{
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                padding-top: 20px;
                border-top: 1px solid #EFEFEF
            }
        }
    }
}
