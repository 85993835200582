.mintdomain-main {
    .mintD-head {
        margin-top: 101px;

        h2 {
            font-weight: 600;
            font-style: normal;
            font-size: 45px;
            color: #ff0083;
            margin: 15px 0px;
        }

        p {
            font-weight: 500;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: #8c8787;
        }
    }

    .walletMint {
        background: #ffffff;
        border: 1px solid #efefef;
        /* Save */

        box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
        border-radius: 5px;
        padding: 25px 35px;
        margin-top: 50px;


        position: relative;
        $border: 1.5px;
        color: #FFF;
        background: #2b2326;
        background-clip: padding-box;
        border: solid $border transparent;
        border-radius: 30px;


        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;
            margin: -$border;
            /* !importanté */
            border-radius: inherit;
            /* !importanté */
            background: var(--gradient, linear-gradient(180deg, #FF0083 0%, #171717 103.07%));
        }

        .chooseWallet {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 39px;
            border-bottom: 1px solid #efefef;

            span {
                font-weight: 500;
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    margin-right: 20px;
                }
            }

            span:nth-child(2) {
                background-color: #dedede;
                width: 45px;
                height: 1px;
                margin: 0px 20px;
            }
        }
    }

    .metaWallet {
        padding: 39px 0px;

        .metamask {
            cursor: pointer;
            background: unset;
            border: 1px solid #FF0083;
            /* Save */
            text-align: center;
            box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            width: 100%;
            padding-top: 40px;
            padding-bottom: 40px;

            &:hover {
                background-color: #FF0083;
                color: #fff;
            }

            h6 {
                font-weight: 600;
                font-style: normal;
                font-weight: 600;
                font-size: 25px;
                color: #fff;
                margin-top: 24px;
            }
        }

        .walletkk {
            min-width: 86px;
        }
    }

    .clearWallet {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 28px;
        border-top: 1px solid #efefef;

        p {
            font-weight: 600;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            color: #ff0083;
            margin-left: 13px;
        }
    }

    .mintConfirm {
        padding: 50px 0px;

        h3 {
            font-weight: 600;
            font-style: normal;
            font-weight: 600;
            font-size: 30px;
            color: #fff;
            margin-bottom: 13px;
        }

        p {
            font-weight: 600;
            font-style: normal;
            font-size: 18px;
            color: #fff;
        }

        .mintConfirmTopB {
            padding: 30px 0px;
            margin: 30px 0px;
            border-top: 1px solid #efefef;
            border-bottom: 1px solid #efefef;

            p:nth-child(1) {
                font-weight: 500;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #878686;
            }

            p:nth-child(2) {
                font-weight: 500;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                color: #222222;
                margin-top: 10px;
            }

            .chekcokd {
                margin-top: 21px;
                align-items: center;

                input {}

                p {
                    margin-top: 0px;
                    font-weight: 500;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    color: #222222;
                    margin-left: 11.8px;
                }
            }
        }
    }

    .warningDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 31px;

        p {
            margin-left: 18px;
            font-weight: 500;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            color: #fff;
        }
    }

    .mP-txt {
        font-weight: 600;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #838383;
    }

    .tct {
        font-weight: 600;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        color: #fff !important;
    }

    //chekbox
    /* The containerr */
    .containerr {
        display: block;
        position: relative;
        padding-left: 21px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .containerr input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        border-radius: 15px;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: -2px;
        left: 0;
        height: 19px;
        width: 19px;
        border-radius: 5px;
        background-color: #f1f2f9;
    }

    /* On mouse-over, add a grey background color */
    .containerr:hover input~.checkmark {
        background-color: #f1f2f9;
    }

    /* When the checkbox is checked, add a blue background */
    .containerr input:checked~.checkmark {
        background-color: #ff0083;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .containerr input:checked~.checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .containerr .checkmark:after {
        left: 7px;
        top: 4px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }
}

.rotate {
    animation: rotation 3s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(359deg);
    }

    to {
        transform: rotate(0deg);
    }
}

@media (max-width: 601px) {
    .mintdomain-main .walletMint {
        padding: 25px 13px;
    }

    .mintdomain-main .walletMint .chooseWallet span:nth-child(2) {
        visibility: hidden;
    }

    .mintdomain-main .walletMint .chooseWallet {
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .mintdomain-main .walletMint .chooseWallet span {
        margin-bottom: 20px;
    }

    .mintdomain-main .mintD-head {
        margin-top: 35px;
    }
}


.oops-modal {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(2.5px);

    .modal-dialog {
        max-width: 495px;
    }

    .modal-content {
        background: #111111;
        border-radius: 10px;
        border: none;
        overflow: hidden;
        // maxheight: 409px;
        overflow: hidden;
        position: relative;
        background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);
        backdrop-filter: blur(92px);
        border-radius: 30px;

        -webkit-backdrop-filter: blur(35.1px);
        backdrop-filter: blur(35.1px);

        &:after {
            content: '';
            position: absolute;
            inset: 0px;
            border-radius: inherit;
            background-color: #ffffff;
            -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            border: 1.7px solid transparent;
            background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);

            background: linear-gradient(#111111 0 0) padding-box, linear-gradient(181.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
            z-index: -1;
        }
    }

    .modal-header {
        justify-content: center;
        // background: #111111;
        border: none;
        padding: 20px 30px;

        .modal-title {
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 100%;
            text-align: center;
            text-transform: uppercase;
            color: #FFF;

        }
        h5{
            color: #FFF;
text-align: start !important;
font-size: 18px;
display: flex;
align-items: flex-start;
justify-content: flex-start;
font-style: normal;
font-weight: 600;
line-height: 100%; /* 18px */
text-transform: capitalize;
        }

        .btn-close {
            background: url("../../Assests/close-new.svg") !important;
            background-size: contain !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            padding: 15px;
            border: none;
            opacity: 1;
            position: absolute;
            top: 15px;
            right: 15px;
        }

        .back-btn {
            position: absolute;
            top: 15px;
            left: 15px;
        }
    }

    .modal-body {
        padding: 20px 30px;
        padding-top: 16px;

        .modal_dataaa {
            .share_div {
                padding: 20px 20px;
                border-radius: 10px;
                background: rgba(255, 255, 255, 0.04);
                margin-top: 25px;
                margin-bottom: 10px;
                .imggg_div {
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    justify-content: center;
                    margin-top: 18px;
                }

            }
            .inner__modals {
                background-image: url(../../../src/Assests/modalcard.svg);
                background-repeat: no-repeat;
                width: 252px;
                height: 267.46px;
                margin: 0 auto;
            }

            h1 {
                color: var(--White, #FFF);
                font-size: 15.873px !important;
                font-style: normal;
                font-weight: 700;
                padding: 19px;
                line-height: 150%;
                /* 23.81px */
            }
h3{
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 12px */
    text-transform: uppercase;
}
          

            h6 {
                padding: 16px 20px;
                border-radius: 5px;
                background: rgba(255, 255, 255, 0.04);
                color: #FFF;
                display: flex;
                align-items: center;
                gap: 6px;
                font-size: 14px;
                justify-content: center;
                font-style: normal;
                font-weight: 500;
                line-height: 100%;
                /* 14px */
            }
        }

        .inner-content {
            .uper-img {
                margin-bottom: 20px;
            }

            .bottom-content {
                .main-para {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 120%;
                    text-align: center;
                    color: #FFF;
                    margin-bottom: 30px;
                }

                .inner-exchanges {
                    h6 {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 100%;
                        text-align: center;
                        color: #FFF;
                        margin-bottom: 18px;
                    }

                    .exchange-names {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 25px;
                    }

                    .para-or {
                        margin-bottom: 20px;
                    }

                    .twice-btns {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 20px;

                        button {
                            flex: 0 0 48%;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 100%;
                            color: #292929;
                            height: 30px;
                            border-radius: 5px;
                            border: none;
                            display: flex;
                            align-items: center;
                        }

                        .uni-swap {
                            background: #FFE6F2;
                        }

                        .btn-chan {
                            background: #E8FBF2;
                        }
                    }
                }
            }
        }
    }

    .buytomi-modaldiv {
        display: flex;
        flex-direction: column;
        align-items: center;

        .para {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 120%;
            text-align: center;
            color: #FFF;
            // max-width: 328px;
            width: 100%;
            margin: 30px auto;
        }

        .buytomi-btn {
            background: #FF0083;
            box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
            border-radius: 35px;
            border: none;
            padding: 16px 20px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            color: #FFFFFF;
            max-width: 228px;
            width: 100%;
            margin: 0 auto;
            margin-bottom: 40px;
        }

    }

    .another-div {
        .parent-items {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px 0;

            a {
                flex: 0 0 25%;

                .inner-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    h6 {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 100%;
                        color: #fff;
                        margin-top: 10px;
                        text-align: center;
                    }
                }
            }
        }

        .para-or {
            margin: 20px 0;
        }

        .twice-btns {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            padding: 0 20px;

            a {
                flex: 0 0 48%;
            }

            button {
                width: 100%;
                height: 47px !important;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #292929;
                height: 30px;
                border-radius: 5px;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .uni-swap {
                background: #FFE6F2;
            }

            .btn-chan {
                background: #E8FBF2;
            }
        }
    }

    .waiting-div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;

        .waiting-text {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 140%;
            text-align: center;
            color: #0F0F0F;
            margin-top: 20px;
        }
    }
}
.exchangemodal{
    .modal-header {
        justify-content: flex-start !important;
    }
}


@media (max-width:600px) {
    .oops-modal .modal-body {
        padding: 20px 15px !important;
    }

    .oops-modal .another-div .twice-btns {
        padding: 0 !important;
    }

    .oops-modal .another-div .parent-items a {
        flex: 0 0 21% !important;
    }
}

.slide-right {
    animation: 1s slide-right;
}

@keyframes slide-right {
    from {
        transform: translate3d(100%, 0, 0);
    }

    to {
        transform: translate3d(0%, 0, 0);
    }
}

.slide-left {
    animation: 1s slide-left;
}

@keyframes slide-left {
    from {
        transform: translate3d(-100%, 0, 0);
    }

    to {
        transform: translate3d(0%, 0, 0);
    }
}

.buyyytomi {
    color: #FFF;
    font-size: 39.942px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: 100%;
    padding-top: 30px;
    text-align: center;
}

.linesimggg {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}