.refferal-section {
    position: relative;
    
    padding-top: 157px;
    padding-bottom: 115px;
    .banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 783px;
        object-fit: cover;
    }
    .main-head{
        margin-bottom: 16px;
        h6{
            font-style: normal;
            font-weight: 700;
            font-size: 25px;
            line-height: 24px;
            letter-spacing: 0.02em;
            color: #FFF;
        }
    }

    .bg-refferal {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        z-index: -1;
        height: 512px;
    }

    .main-heading {
        padding-top: 100px;
        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 50px;
            line-height: 110%;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 16px;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            text-align: center;
            line-height: 140%;
            color: #FFFFFF;
        }
    }

    .inner-box {
        border-radius: 5px;
        background: #1F1F1F;
        box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.05);
        padding: 60px;
        margin-top: 53px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);
        border-radius: 12px;
            -webkit-backdrop-filter: blur(35.1px);
            backdrop-filter: blur(35.1px);
        
            &:after {
              content: '';
              position: absolute;
              inset: 0px;
              border-radius: inherit;
              background-color: #ffffff;
              -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
              -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
              -webkit-mask-composite: xor;
              border: 1.7px solid transparent;
              background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);
    
              background: linear-gradient(#111111 0 0) padding-box, linear-gradient(181.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
              z-index: -1;
            }

        .left {
            .inner-heading {
                margin-bottom: 31px;

                h6 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 110%;
                    color: #FFF;
                    margin-bottom: 12px;
                }

                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 140%;
                    color: #9D9D9D;
                }
            }

            .bottom-box {
                border-radius: 15px;
                border: 1px solid rgba(255, 255, 255, 0.03);
                background: rgba(255, 255, 255, 0.03);
                box-shadow: 0px 10.511px 52.555px 0px rgba(0, 0, 0, 0.05);
                padding: 51px 35px;


                h6 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    text-transform: capitalize;
                    color: #9D9D9D;
                    margin-bottom: 14px;
                }

                .address-div {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    
                    border-radius: 160px !important;
background: rgba(255, 255, 255, 0.03);
                    border-radius: 10px;
                    padding: 14px 15px;

                    p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 120%;
                        color: #FFF;
                    }
                }
            }
        }
    }
    .inner-box1{
        border-radius: 5px;
        position: relative;
        background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);

    
        border-radius: 12px;

    
            -webkit-backdrop-filter: blur(35.1px);
            backdrop-filter: blur(35.1px);
        
            &:after {
              content: '';
              position: absolute;
              inset: 0px;
              border-radius: inherit;
              background-color: #ffffff;
              -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
              -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
              -webkit-mask-composite: xor;
              border: 1.7px solid transparent;
              background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);
    
              background: linear-gradient(#111111 0 0) padding-box, linear-gradient(181.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
              z-index: -1;
            }
        padding: 60px;
        margin-top: 53px;
        .left{
            .bottom-box{
                position: relative;
                h6{
                    color: #FFF;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 100%; /* 18px */
                    text-transform: capitalize;
                    margin-bottom: 20px;
                }
                .imgpen{
                    position: absolute;
                    top: 57px;
    left: 126px;
                }
                input{
                    border-radius: 185px;
                    border: 1px solid rgba(255, 255, 255, 0.03);
                    background: rgba(255, 255, 255, 0.03);
                    box-shadow: 0px 10.511px 52.555px 0px rgba(0, 0, 0, 0.05);
                    height: 60px;
                    padding: 19px 1079px 20px 20px;

                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 26px;
                    color: #FFF;
                    width: 100%;
                    &::placeholder{
                        color: #B1B1B1;
                    }
                }
                .btn-save{
                    background: #FF0083;
                    border-radius: 45px;
                    border: none;
                    padding: 14px 52px;

                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 100%;
                    color: #FFFFFF;
                    max-width: 216px;
                    width: 100%;
                    margin-left: auto;
                    display: block;
                    margin-top: 30px;
                }
            }
        }
    }

    .refer-tabs {
        margin-top: 35px;
        margin-bottom: 26px;
        border-bottom: 1px solid #282828;

        .nav-link {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            color: #9D9D9D;
            border: none !important;
            border-bottom: 2px solid transparent !important;
            padding: 10px;
            background-color: transparent;
        }

        .nav-item.show .nav-link,
        .nav-link.active {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 100%;
            color: #FF0083;
            border: none;
            border-bottom: 2px solid #FF0083 !important;
            padding: 10px;
        }
    }

    .table-responsive {
        border-radius: 10px;
        border: 1px solid #282828;
        overflow-x: unset;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
        position: relative;
        background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);
        border-radius: 12px;
            -webkit-backdrop-filter: blur(35.1px);
            backdrop-filter: blur(35.1px);
        
            &:after {
              content: '';
              position: absolute;
              inset: 0px;
              border-radius: inherit;
              background-color: #ffffff;
              -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
              -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
              -webkit-mask-composite: xor;
              border: 1.7px solid transparent;
              background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);
    
              background: linear-gradient(#111111 0 0) padding-box, linear-gradient(181.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
              z-index: -1;
            }

        

        th {
            border: none;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #FFF !important;
            padding: 40px 15px;
            vertical-align: middle;
            border-bottom: 1.051px rgba(255, 255, 255, 0.09);
            white-space: nowrap;
            p{
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }

        td {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 100%;
            color: #FFF;
            border: none;
            border-top: 1px solid #282828;
            padding: 40px 15px;
            vertical-align: middle;
            white-space: nowrap;
        }
    }

    .footer-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        border: 1px solid #1F1F1F;
        background: #1F1F1F;
        padding: 14px 20px;
        margin-top: 10px;

        .left-f {
            h6 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #A0A1A1;
            }
        }

        .right-f {
            .page-link:focus {
                box-shadow: none;
                background-color: unset;
            }

            .page-link:hover {
                box-shadow: none;
                background-color: unset;
            }

            .page-link {
                border: none;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                background: transparent;
                color: #A0A1A1;

                &.active {
                    background: #FF0083;
                    border-radius: 5px;
                    color: #FFFFFF;
                }
            }

            .page-item:first-child .page-link {
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #A0A1A1;
            }
        }
    }
}
.banner-bg{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 783px;
    object-fit: cover;
}
.errorTxt{
    color: #FF0083;

}
.cash-back{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.08), 0px 10px 50px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 24px 20px;
    margin-bottom: 26px;
    p{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 120%;
        text-transform: capitalize;
        color: #404040;
    }
    h6{
        font-style: normal;
        font-weight: 600;
        font-size: 27px;
        line-height: 120%;
        color: #FF0083;
    }
}

.total-compensation{
    background: #1F1F1F;
    border-radius: 10px;
    padding: 48px 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;

    position: relative;
    background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);

    border-radius: 12px;

        -webkit-backdrop-filter: blur(35.1px);
        backdrop-filter: blur(35.1px);
    
        &:after {
          content: '';
          position: absolute;
          inset: 0px;
          border-radius: inherit;
          background-color: #ffffff;
          -webkit-mask: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff)) content-box, -webkit-gradient(linear, left top, left bottom, color-stop(0, #fff));
          -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
          -webkit-mask-composite: xor;
          border: 1.7px solid transparent;
          background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0.00) 110.97%), rgba(29, 29, 29, 0.05);

          background: linear-gradient(#111111 0 0) padding-box, linear-gradient(181.07deg, rgba(255, 0, 131, 0.8) -8.28%, rgba(49, 49, 49, 0) 57.61%) border-box;
          z-index: -1;
        }
    .left{
        .text-content{
            h6{
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                letter-spacing: 0.02em;
                color: #FFFFFF;
                margin-bottom: 15px;
            }
            h5{
                font-style: normal;
                font-weight: 700;
                font-size: 45px;
                line-height: 100%;
                letter-spacing: 0.02em;
                color: #FFFFFF;
                span{
                    margin-left: 15px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 26px;
                    letter-spacing: 0.1px;
                    color: #FED7FC;
                }
            }
        }
    }
    .right{
        max-width: 233px;
        width: 100%;
        .btn-claimall{
            border-radius: 35px;
            background: #FF0083;
            padding: 18px 37px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 100%;
            color: #FFFFFF;
            width: 100%;
            border: none;

        }
    }

}

@media (max-width:991px){
    .total-compensation .left .text-content h6{
        font-size: 18px;
    }
    .total-compensation .left .text-content h5{
        font-size: 28px;
    }
}

@media (max-width:600px){
    .refferal-section .main-heading p{
        font-size: 16px;
    }
    .refferal-section .inner-box{
        padding: 15px;
        flex-direction: column;
        gap: 25px;
    }
    .refferal-section .inner-box .left .bottom-box{
        padding: 15px;
    }
    .refferal-section .refer-tabs{
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        &::-webkit-scrollbar{
            display: none;
        }
    }
    .refferal-section .refer-tabs .nav-link{
        white-space: nowrap;
    }
    .refferal-section .footer-content{
        flex-direction: column;
        justify-content: center;
        gap: 15px;
    }
    .refferal-section .inner-box .left .inner-heading p{
        font-size: 16px;
    }
    .refferal-section .inner-box1{
        padding: 15px;
    }
    .setting-secti .bg-refferal{
        height: 415px !important;
    }
    .refferal-section .inner-box1 .left .bottom-box .btn-save{
        max-width: 100%;
    }
    .cash-back h6{
        font-size: 18px;
    }
    .cash-back p{
        font-size: 16px;
    }
    .total-compensation{
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 20px;
        gap: 20px;
        margin-bottom: 30px;
    }
    .total-compensation .right{
        max-width: 100%;
    }
    .refferal-section .main-head h6{
        font-size: 20px;
    }
    
}
.text-common{
    color: #FF0083 !important;
    line-height: 18px !important;
}
.bbbtn-claim{
    background: #FF0083;
    border-radius: 5px;
    border: none;
    padding: 13px 25px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #FFFFFF;
}

.custom-checkbox{

    .form-group{
        margin-bottom: 0;
    }

.form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .form-group label {
    position: relative;
    cursor: pointer;
    margin-bottom: 0;
  }
  
  .form-group label:before {
    content:'';
    -webkit-appearance: none;
    background-color: transparent;
    border: 2px solid #ff0083;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #ff0083;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}