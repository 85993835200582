.demo{
    .pagination{
border: none;
    }
    border: none !important;
    .page-link {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 100%;
        color: #BFC3C7;
        border: none !important;
    }
    .active {
        background: #FF0083 !important;
        border-radius: 5px;
        color: #fff;
    }
}